import { all, takeLatest, put, call } from "redux-saga/effects";
import { setRoute } from "./actions";
import { INIT_SET_CURRENT_ROUTE, INITIATE_EXPORT_TEMPLATE } from "./actionTypes";
import { storage } from "../config/storage";
import { DownloadTemplates } from "../config/APINames";
import axios from "../config/axiosCall";
import { Error_Msg } from "../config";
import { postRequestDetails } from "../config/requestHeaders";
import { downloadFile } from '../helper';
import { generateNotification } from "../helper/generateNotification";
export default function* watchCommonAPI() {
  yield all([takeLatest(INIT_SET_CURRENT_ROUTE, initSetCurrentRoute),
  takeLatest(INITIATE_EXPORT_TEMPLATE, initiateExportTemplate)
  ]);
}

function* initSetCurrentRoute(action) {
  const { payload } = action;

  try {
    yield storage.setItem("currPath", JSON.stringify(payload));
    yield put(setRoute(payload));
  } catch (error) {
  }
};

function* initiateExportTemplate(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    requestDetails.responseType = "blob";
    yield (requestDetails.data = {
      ClientName: payload?.clientName,
      UserName: payload?.userName,
      FileName: payload?.fileName,
    });
    const response = yield call(axios, DownloadTemplates, requestDetails);
    if (response?.data !== Error_Msg) {
      downloadFile(response.data, `${payload?.fileName}`);
    }
    else {
      generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger");
    }
  }
  catch (error) {

  }
}


import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import generateKey from "../../../config/generateKey";
import { downloadFile } from '../../../helper';
import {
    SubmitAdhoc,
    AdhocExportToExcel,
    AdhocExcelValidation,
    AdhocDupsJobs,
    AdhocDupsJobDetails,
    AdhocExcelRecordCount
} from "../../../config/APINames";
import {
    INIT_ADHOCDUPS_EXCEL_VALIDATION,
    INIT_SUBMIT_ADHOCDUPS,
    INIT_ADHOCDUPS_EXPORT_TO_EXCEL,
    INIT_ADHOC_DUPS_JOBS,
    INIT_ADHOC_DUPS_JOBS_DETAILS,
    INIT_ADHOCDUPS_EXCEL_RECORD_COUNT
} from "./actionTypes";
import {
    fetchAdhocDupsExcelValidation,
    fetchAdhocJobList,
    fetchAdhocJobDetails,
    fetchAdhocDupsExcelRecordCount
} from "./actions";
export default function* watchLogin() {
    yield all([
        takeLatest(
            INIT_ADHOCDUPS_EXCEL_VALIDATION,
            initAdhocDupsExcelValidationSaga
        ),
        takeLatest(INIT_SUBMIT_ADHOCDUPS, initSubmitAdhocDupsSaga),
        takeLatest(INIT_ADHOCDUPS_EXPORT_TO_EXCEL, initAdhocDupsExportToExcelSaga),
        takeLatest(INIT_ADHOC_DUPS_JOBS, initAdhocJobList),
        takeLatest(INIT_ADHOC_DUPS_JOBS_DETAILS, initAdhocJobDetails),
        takeLatest(INIT_ADHOCDUPS_EXCEL_RECORD_COUNT, initAdhocDupsExcelRecordCountSaga),
    ]);
}
function* initAdhocDupsExcelValidationSaga(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, AdhocExcelValidation, requestDetails);
        if (response && response.status === 200) {
            if (response.data !== "Invalid Document. Please try with valid one.") {
                yield put(fetchAdhocDupsExcelValidation(response.data.resultStatus));

                callback && callback();
            } else {
                yield put(fetchAdhocDupsExcelValidation(false));
                callback && callback(false);
            }
        }
    } catch (error) {
        generateNotification(error, "danger");
    }
}
function* initAdhocDupsExcelRecordCountSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, AdhocExcelRecordCount, requestDetails);
        if (response && response.status === 200) {
            yield put(fetchAdhocDupsExcelRecordCount(response.data.resultSet.adhocDupsRecordCount));
        }
    } catch (error) {
        generateNotification(error, "danger");
    }
}
function* initSubmitAdhocDupsSaga(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, SubmitAdhoc, requestDetails);
        if (response && response.data.resultStatus) {
            if (
                response.data.message !==
                "Adhoc Dups is submitted successfully and is processing. Kindly check after sometime to check the results."
            ) {

            }
            generateNotification(generateKey(response.data.message), "success");
            callback && callback();
        } else {
            if (response.data.resultSet !== null) {
                callback && callback(response.data.resultSet);
            } else {
                callback && callback();
                generateNotification(generateKey(response.data.message), "danger");
            }
        }
    } catch (error) {
        generateNotification(error, "danger");
    }
}
function* initAdhocDupsExportToExcelSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        requestDetails.responseType = "blob";
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, AdhocExportToExcel, requestDetails);
        if (response && response.status === 200) {
            downloadFile(response.data, "AdhocDups.xlsx");
        }
    } catch (error) {
        generateNotification(error, "danger");
    }
}

function* initAdhocJobList(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(axios, AdhocDupsJobs, requestDetails);
        response = response.data;
        if (
            response ===
            "Request could not be processed at this time. Please contact support team."
        ) {
            generateNotification(generateKey(response), "danger");
        } else {
            yield put(fetchAdhocJobList(response));
        }
    } catch (error) {
        generateNotification(error, "danger");
    }
}

function* initAdhocJobDetails(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(axios, AdhocDupsJobDetails, requestDetails);
        response = response.data;
        if (
            response ===
            "Request could not be processed at this time. Please contact support team."
        ) {
            generateNotification(generateKey(response), "danger");
        }
        else if (response.error === true) {
            generateNotification(generateKey(response.message), "danger");
        }
        else if (response.count === undefined) {
            generateNotification(generateKey(response), "warning");
        } else {
            if (response.count === 0) {
                generateNotification('atcat.noExceptionCaught', 'success');
            }
            yield put(fetchAdhocJobDetails(response));
        }
    } catch (error) {
        generateNotification(error, "danger");
    }
}
import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { storage } from "../../../config/storage";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import generateKey from "../../../config/generateKey";
import { downloadFile } from '../../../helper';
import { Error_Msg } from "../../../config";
import {
    ManualClaimFilterData,
    ManualClaimListOfManualClaims,
    ManualClaimExportToExcel,
    ManualClaimExportAllToExcel,
    ManualClaimDetailsOfAddManualClaim,
    ManualClaimAddManualClaim,
    ManualClaimDetailsOfEachClaim,
    ManualClaimRecordDetails,
    ManualClaimGetOffsetRecord,
    ManualClaimDeleteOffset,
    ManualClaimAddOffset,
    ManualClaimGetOffsetDetails,
    ManualClaimUpdateOffset,
    ManualClaimSaveClaimDetails,
    APPHListCount,
    ManualClaimAPPHListing,
    APPHAddAdvanceFilter,
    APPHGetFilterName,
    APPHDeleteAdvanceFilter,
    APPHGetFilterFields,
    APPHEditFilterPageData,
    ManualClaimAssignAPPHRecord,
    ManualClaimAssignAndCalculateAPPHRecord,
    ManualClaimCalculateClaimValue,
    ManualClaimSearchClaimId,
    ManualClaimAddPayback,
    ManualClaimActionOnManualClaimRecord,
    ReVisitedDupsUnattendedDupsExceptionListHeaders,
    ManualClaimModifiedManualClaim,
    ManualClaimSaveCopyForManualClaim,
} from "../../../config/APINames";
import {
    INIT_MANUAL_CLAIM_FILTER_FIELDS,
    INIT_MANUAL_CLAIM_LIST,
    INIT_MANUAL_CLAIM_RECORDS_EXPORT,
    INIT_MANUAL_CLAIM_RECORDS_EXPORT_ALL,
    INIT_MANUAL_CLAIM_FIELDS,
    INIT_ADD_MANUAL_CLAIM,
    INIT_MANUAL_CLAIM_DETAILS,
    INIT_MANUAL_CLAIM_RECORD_DETAILS,
    INIT_GET_MANUAL_OFFSET_RECORDS,
    INIT_MANUAL_DELETE_OFFSET,
    INIT_ADD_MANUAL_OFFSET,
    INIT_GET_MANUAL_OFFSET_DETAILS,
    INIT_UPDATE_MANUAL_OFFSET,
    INIT_SAVE_MANUAL_CLAIM_DETAILS,
    INIT_GETAPPH_FILTER_NAME,
    INIT_DELETE_APPH_FILTER,
    INIT_GETAPPH_FILTER_FIELDS,
    INIT_EDIT_APPH_FILTER,
    INIT_SAVE_APPH_FILTER,
    INIT_APPH_LIST_COUNT,
    INIT_APPH_LIST,
    INIT_CALCULATE_CLAIM_VALUES,
    INIT_SEARCH_MANUAL_CLAIM_DETAILS,
    INIT_MANUAL_CLAIM_ADD_PAYBACK,
    INIT_MANUAL_CLAIM_APPH_ASSIGN,
    INIT_MANUAL_CLAIM_APPH_ASSIGNCALCULATE,
    INIT_ACTIONS_MANUAL_CLAIM_RECORDS,
    INIT_RECORD_MODIFIED_CLAIM_CREATION_HEADERS,
    INIT_RECORD_MODIFIED_CLAIM_RECORD,
    INIT_SAVE_AND_COPY_MANUAL_CLAIM,
} from "./actionTypes";
import {
    fetchManualClaimList,
    fetchAddManualClaimFields,
    fetchManualClaimDetails,
    fetchManualClaimRecordDetails,
    fetchOffsetRecords,
    fetchOffsetDetails,
    fetchingAddAPPHFilter,
    fetchAddAPPHFilter,
    apphAddFilterFetchComplete,
    apphAddFiltereFetchFailed,
    fetchingEditAPPHFilter,
    editAPPHFilterFetchComplete,
    editAPPHFilterFetchFailed,
    fetchingAPPHColNames,
    apphColNameFetchComplete,
    apphColNameFetchFailed,
    fetchingSaveAPPHFilter,
    saveAPPHFilterFetchComplete,
    saveAPPHFilterFetchFailed,
    fetchingAPPHListCount,
    fetchAPPHListCount,
    apphListCountFetchComplete,
    apphListCountrFetchFailed,
    fetchingAPPHList,
    fetchAPPHList,
    apphListFetchComplete,
    apphListFetchFailed,
    fetchSearchManualClaimDetails,
    fetchRecordModifiedSinceClaimCreationHeaders,
} from "./actions";

export default function* watchLogin() {
    yield all([
        takeLatest(
            INIT_MANUAL_CLAIM_FILTER_FIELDS,
            initGettManualClaimFilterFieldsSaga
        ),
        takeLatest(INIT_MANUAL_CLAIM_LIST, initGetManualClaimListSaga),
        takeLatest(
            INIT_MANUAL_CLAIM_RECORDS_EXPORT,
            initManualClaimRecordsExportSaga
        ),
        takeLatest(
            INIT_MANUAL_CLAIM_RECORDS_EXPORT_ALL,
            initManualClaimRecordsExportAllSaga
        ),
        takeLatest(INIT_MANUAL_CLAIM_FIELDS, initAddManualClaimFieldsSaga),
        takeLatest(INIT_ADD_MANUAL_CLAIM, initAddManualClaimsSaga),
        takeLatest(INIT_SAVE_AND_COPY_MANUAL_CLAIM, initSaveAndCopyManualClaimSaga),
        takeLatest(INIT_MANUAL_CLAIM_DETAILS, initGetManualClaimDetailsSaga),
        takeLatest(
            INIT_MANUAL_CLAIM_RECORD_DETAILS,
            initGetManualClaimRecordDetailsSaga
        ),
        takeLatest(INIT_GET_MANUAL_OFFSET_RECORDS, initGetManualOffsetRecordsSaga),
        takeLatest(INIT_MANUAL_DELETE_OFFSET, initDeleteManualOffsetSaga),
        takeLatest(INIT_ADD_MANUAL_OFFSET, initAddManualOffsetSaga),
        takeLatest(INIT_GET_MANUAL_OFFSET_DETAILS, initGetManualOffsetDetailsSaga),
        takeLatest(INIT_UPDATE_MANUAL_OFFSET, initUpdateManualOffsetSaga),
        takeLatest(INIT_SAVE_MANUAL_CLAIM_DETAILS, initSaveManualClaimDetailsSaga),
        takeLatest(INIT_GETAPPH_FILTER_NAME, initGetAPPHFilterNameSaga),
        takeLatest(INIT_DELETE_APPH_FILTER, initDeleteAPPHFilterSaga),
        takeLatest(INIT_GETAPPH_FILTER_FIELDS, initGetAPPHFilterColsSaga),
        takeLatest(INIT_EDIT_APPH_FILTER, initEditAPPHPageFilterSaga),
        takeLatest(INIT_SAVE_APPH_FILTER, initSaveAPPHFilterNameSaga),
        takeLatest(INIT_APPH_LIST_COUNT, initGetApphCountSaga),
        takeLatest(INIT_APPH_LIST, initApphListFetchSaga),
        takeLatest(INIT_CALCULATE_CLAIM_VALUES, initCalculateClaimValuessSaga),
        takeLatest(
            INIT_SEARCH_MANUAL_CLAIM_DETAILS,
            initSearchManualClaimDetailsSaga
        ),
        takeLatest(INIT_MANUAL_CLAIM_ADD_PAYBACK, initManualClaimAddPaybackSaga),
        takeLatest(INIT_MANUAL_CLAIM_APPH_ASSIGN, initManualAPPHAssignSaga),
        takeLatest(
            INIT_MANUAL_CLAIM_APPH_ASSIGNCALCULATE,
            initManualAPPHAssignCalculateSaga
        ),
        takeLatest(
            INIT_ACTIONS_MANUAL_CLAIM_RECORDS,
            initActionsManualClaimRecordSaga
        ),
        takeLatest(
            INIT_RECORD_MODIFIED_CLAIM_CREATION_HEADERS,
            initRecodModifiedClaimsHeaders
        ),
        takeLatest(
            INIT_RECORD_MODIFIED_CLAIM_RECORD,
            initRecordModifiedClaimsRecords
        ),
    ]);
}

function* initGettManualClaimFilterFieldsSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { userName, clientName } = sessionDetails || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName,
            userName,
        });
        const response = yield call(axios, ManualClaimFilterData, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetManualClaimListSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimListOfManualClaims,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            yield put(fetchManualClaimList(response.data));
        }
    } catch (error) {
        generateNotification(
            "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
            "danger"
        );
    }
}

function* initManualClaimRecordsExportSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        requestDetails.responseType = "blob";
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimExportToExcel,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            downloadFile(response.data, "ManualClaims.xlsx");
        }
    } catch (error) {
        generateNotification(
            "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
            "danger"
        );
    }
}

function* initManualClaimRecordsExportAllSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        requestDetails.responseType = "blob";
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimExportAllToExcel,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            downloadFile(response.data, "ManualClaims.xlsx");
        }
    } catch (error) {
        generateNotification(
            "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
            "danger"
        );
    }
}

function* initAddManualClaimFieldsSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { userName, clientName } = sessionDetails || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName,
            userName,
        });
        const response = yield call(
            axios,
            ManualClaimDetailsOfAddManualClaim,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            yield put(fetchAddManualClaimFields(response.data));
        }
    } catch (error) {
        generateNotification(
            "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
            "danger"
        );
    }
}

function* initAddManualClaimsSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimAddManualClaim,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initSaveAndCopyManualClaimSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimSaveCopyForManualClaim,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        }
        else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetManualClaimDetailsSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimDetailsOfEachClaim,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            yield put(fetchManualClaimDetails(response.data));
            if (action.callback) action.callback(response.data);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetManualClaimRecordDetailsSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimRecordDetails,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            yield put(fetchManualClaimRecordDetails(response.data));
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetManualOffsetRecordsSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimGetOffsetRecord,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            yield put(fetchOffsetRecords(response.data));
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initDeleteManualOffsetSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, ManualClaimDeleteOffset, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback();
            generateNotification(generateKey(response.data.message), "success");
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initAddManualOffsetSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, ManualClaimAddOffset, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response.data);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetManualOffsetDetailsSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimGetOffsetDetails,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response.data);
            yield put(fetchOffsetDetails(response.data));
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initUpdateManualOffsetSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, ManualClaimUpdateOffset, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response.data);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initSaveManualClaimDetailsSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimSaveClaimDetails,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data.message === "Correct all the error and retry") {
            if (response.data.resultSet.validations.length > 0) {
                if (action.callback) action.callback(response);
            }
            else { generateNotification(generateKey(response.data.resultSet.validations), "danger"); }
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initManualAPPHAssignSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimAssignAPPHRecord,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            generateNotification(generateKey(response.data.message), "success");
            if (action.callback) action.callback();
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initManualAPPHAssignCalculateSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimAssignAndCalculateAPPHRecord,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            generateNotification(generateKey(response.data.message), "success");
            if (action.callback) action.callback(response.data.resultSet);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetAPPHFilterColsSaga(action) {
    const { payload } = action || {};
    yield put(fetchingAddAPPHFilter());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, APPHGetFilterFields, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            yield put(fetchAddAPPHFilter(response.data));
            yield put(apphAddFilterFetchComplete());
        } else {
            yield put(apphAddFiltereFetchFailed());
        }
    } catch (error) {
        yield put(apphAddFiltereFetchFailed());
    }
}

function* initEditAPPHPageFilterSaga(action) {
    const { payload } = action || {};
    yield put(fetchingEditAPPHFilter());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, APPHEditFilterPageData, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response);
            yield put(editAPPHFilterFetchComplete());
        } else {
            yield put(editAPPHFilterFetchFailed());
        }
    } catch (error) {
        yield put(editAPPHFilterFetchFailed());
    }
}

function* initDeleteAPPHFilterSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { userName, clientName } = sessionDetails || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            userName,
            clientName,
        });
        const response = yield call(axios, APPHDeleteAdvanceFilter, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetAPPHFilterNameSaga(action) {
    const { payload } = action || {};
    yield put(fetchingAPPHColNames());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, APPHGetFilterName, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response);
            yield put(apphColNameFetchComplete());
        } else {
            yield put(apphColNameFetchFailed());
        }
    } catch (error) {
        yield put(apphColNameFetchFailed());
    }
}

function* initSaveAPPHFilterNameSaga(action) {
    const { payload } = action || {};
    yield put(fetchingSaveAPPHFilter());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, APPHAddAdvanceFilter, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
            yield put(saveAPPHFilterFetchComplete());
        } else {
            yield put(saveAPPHFilterFetchFailed());
        }
    } catch (error) {
        yield put(saveAPPHFilterFetchFailed());
    }
}

function* initGetApphCountSaga(action) {
    const { payload } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { clientName, userName } = sessionDetails || {};
    yield put(fetchingAPPHListCount());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            userName,
            clientName: clientName.toLowerCase(),
        });
        const response = yield call(axios, APPHListCount, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
            yield put(fetchAPPHListCount(0));
        } else if (response.data !== Error_Msg) {
            yield put(fetchAPPHListCount(response.data));
        }
        yield put(apphListCountFetchComplete());
    } catch (error) {
        yield put(apphListCountrFetchFailed());
    }
}

function* initApphListFetchSaga(action) {
    const { payload } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { clientName, userName, clientId } = sessionDetails || {};
    yield put(fetchingAPPHList());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName,
            userName,
            ClientID: clientId,
        });
        const response = yield call(axios, ManualClaimAPPHListing, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
            yield put(
                fetchAPPHList({
                    apphListForManualClaims: [],
                    gridListModel: {},
                })
            );
        } else if (response.data !== Error_Msg) {
            yield put(fetchAPPHList(response.data.resultSet));
            yield put(apphListFetchComplete());
        } else {
            yield put(apphListFetchFailed());
        }
    } catch (error) {
        yield put(apphListFetchFailed());
    }
}

function* initCalculateClaimValuessSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimCalculateClaimValue,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initSearchManualClaimDetailsSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimSearchClaimId,
            requestDetails
        );
        if (response.data === Error_Msg) {
            const regMatch = /[a-z]/i.test(payload.CommandArgs);
            if (regMatch) {
                generateNotification(
                    "atcat.noClaimFoundForTheSpecifiedClaimId",
                    "danger"
                );
            } else {
                generateNotification(
                    "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                    "danger"
                );
            }
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response.data);
            yield put(fetchSearchManualClaimDetails(response.data));
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initManualClaimAddPaybackSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, ManualClaimAddPayback, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (response.data.resultStatus === true) {
                if (action.callback) action.callback();
                generateNotification(generateKey(response.data.message), "success");
            } else {
                generateNotification(
                    generateKey(response.data.resultSet.validations[0]),
                    "danger"
                );
            }
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initActionsManualClaimRecordSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualClaimActionOnManualClaimRecord,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response.data);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initRecodModifiedClaimsHeaders(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ReVisitedDupsUnattendedDupsExceptionListHeaders,
            requestDetails
        );
        response = response.data;
        response.length > 0
            ? yield put(fetchRecordModifiedSinceClaimCreationHeaders(response))
            : yield put(fetchRecordModifiedSinceClaimCreationHeaders([]));
        callback !== undefined && callback(response);
    } catch (error) {
        generateNotification(
            "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
            "danger"
        );
    }
}
function* initRecordModifiedClaimsRecords(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ManualClaimModifiedManualClaim,
            requestDetails
        );
        response = response.data;
        response =
            response.resultSet.recordDetails.length > 0
                ? response.resultSet.recordDetails
                : [];
        callback !== undefined && callback(response);
    } catch (error) {
        generateNotification(
            "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
            "danger"
        );
    }
}

import { all, call, put, takeLatest } from "redux-saga/effects";
import { postRequestDetails } from "../../../config/requestHeaders";
import axios from "../../../config/axiosCall";
import { generateNotification } from "../../../helper/generateNotification";
import { AtcatAdminName, Error_Msg } from "../../../config";
import generateKey from "../../../config/generateKey";
import { downloadFile } from '../../../helper';
import {
  INIT_GET_TOTAL_USERS,
  INIT_GET_ACTIVE_USERS,
  INIT_GET_INACTIVE_USERS,
  INIT_GET_USERS_LIST,
  INIT_GET_USER_LANGUAGES,
  INIT_USER_ACTION,
  INIT_SAVE_USER_DATA,
  INIT_GET_COUNTRY_LIST,
  INIT_GET_CLIENT_LIST,
  INIT_SAVE_USER_FILTER,
  INIT_FETCH_USER_FILTER_COL_NAMES,
  INIT_DELETE_FILTER,
  INIT_FETCH_USER_FILTER_DETAILS,
  INIT_FETCH_FILTER_NAMES,
  INIT_GET_EXPORT_TO_EXCEL,
  INIT_USERMANAGEMENT_HEADER_LIST,
} from "./actionTypes";
import { storage } from "../../../config/storage";
import {
  fetchingTotalUsers,
  fetchTotalUsers,
  fetchFilteredTotalUsers,
  totalUsersFetchingComplete,
  fetchingActiveUsers,
  fetchActiveUsers,
  activeUsersFetchingComplete,
  fetchingInactiveUsers,
  fetchInactiveUsers,
  inactiveUsersFetchingComplete,
  fetchingUsersList,
  fetchUsersList,
  usersListFetchingComplete,
  fetchUserLanguages,
  userLanguagesFetchComplete,
  userLanguagesFetching,
  userActionSuccess,
  userActionFailed,
  userDataSaveSuccess,
  userDataSaveFailed,
  fetchingCountryList,
  fetchCountryList,
  countryListFetchingComplete,
  fetchingClientList,
  fetchClientList,
  clientListFetchingComplete,
  fetchUserColNames,
  fetchingUserColNames,
  userColNameFetchComplete,
  userColNameFetchFailed,
  fetchingExportToExcel,
  exportToExcelFetchingComplete,
  fetchExportToExcel,
  getUserManagementHeaderList,
} from "./actions";
import {
  AtcatUserCount,
  ActiveUserList,
  InactiveUserList,
  GetUserList,
  GetLanguages,
  ActionOnUser,
  AddNewUser,
  UpdateUserDetails,
  GetCountryList,
  ClientMClientList,
  UserManagementUserFunctionality,
  UserManagementGetActiveUsersList,
  UserManagementGetTotalUsersList,
  UserManagementActionsOnUser,
  UserManagementUpdateUserDetails,
  UserManagementAddNewUser,
  UserManagementGetCountryList,
  AddAdvancedFilter,
  UserManagementGetFilterFields,
  DeleteAdvancedFilter,
  GetEditFilterPageData,
  UserManagementGetFilterName,
  UserManagementExportToExcel,
  GetUserManagementHeaderList,
} from "../../../config/APINames";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import exportToExcel from "../../../helper/exportToExcel";

export default function* watchLogin() {
  yield all([
    takeLatest(INIT_GET_TOTAL_USERS, initGetTotalUsers),
    takeLatest(INIT_GET_ACTIVE_USERS, initGetActiveUsers),
    takeLatest(INIT_GET_INACTIVE_USERS, initGetInactiveUsers),
    takeLatest(INIT_GET_USERS_LIST, initGetUsersList),
    takeLatest(INIT_GET_USER_LANGUAGES, initGetUserLanguagesSaga),
    takeLatest(INIT_USER_ACTION, initUserActionSaga),
    takeLatest(INIT_SAVE_USER_DATA, initSaveUserDataSaga),
    takeLatest(INIT_GET_COUNTRY_LIST, initGetCountryListSaga),
    takeLatest(INIT_GET_CLIENT_LIST, initGetClientListSaga),
    takeLatest(INIT_SAVE_USER_FILTER, initSaveUserFilterSaga),
    takeLatest(INIT_FETCH_USER_FILTER_COL_NAMES, initGetUserColNamesSaga),
    takeLatest(INIT_DELETE_FILTER, initDeleteUserFilterSaga),
    takeLatest(INIT_FETCH_USER_FILTER_DETAILS, initGetUserFilterDetailsSaga),
    takeLatest(INIT_FETCH_FILTER_NAMES, initGetUserFilterNamesSaga),
    takeLatest(INIT_GET_EXPORT_TO_EXCEL, initGetUserRecordsExportSaga),
    takeLatest(INIT_USERMANAGEMENT_HEADER_LIST, initGetUserManagementHeaderList),
  ]);
}

function* initGetUserRecordsExportSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName } = sessionDetails || {};
  yield put(fetchingExportToExcel());
  try {
      const requestDetails = postRequestDetails();
      requestDetails.responseType = "blob";
    yield (requestDetails.data = {
      ...payload,
      clientName: clientName.toLowerCase(),
      userName,
    });
    const response = yield call(
      axios,
      UserManagementExportToExcel,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchExportToExcel(response.data));
      downloadFile(response.data, "ManageUsers.xlsx");
    }

    yield put(exportToExcelFetchingComplete());
  } catch (error) {
    yield put(exportToExcelFetchingComplete());
  }
}

function* initGetUserFilterNamesSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, sourceDomain, clientName, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userId,
      userName,
      sourceDomain,
      clientName,
    });
    const response = yield call(
      axios,
      UserManagementGetFilterName,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetUserFilterDetailsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, sourceDomain, clientName, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      sourceDomain,
      userId,
      userName,
    });
    const response = yield call(axios, GetEditFilterPageData, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initDeleteUserFilterSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userName,
      clientName,
    });
    const response = yield call(axios, DeleteAdvancedFilter, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetUserColNamesSaga(action) {
  const { payload } = action || {};
  yield put(fetchingUserColNames());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      UserManagementGetFilterFields,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchUserColNames(response.data));
      yield put(userColNameFetchComplete());
    } else {
      yield put(userColNameFetchFailed());
    }
  } catch (error) {
    yield put(userColNameFetchFailed());
  }
}

function* initSaveUserFilterSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, AddAdvancedFilter, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetTotalUsers(action) {
  const { payload, callback } = action || {};
  const { clientName = "" } = payload;
  yield put(fetchingTotalUsers());
  try {
    let url;
    if (clientName.toUpperCase() === AtcatAdminName) {
      url = AtcatUserCount;
    } else {
      url = UserManagementGetTotalUsersList;
    }
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      if (payload && payload.filterCriteria) {
        yield put(fetchFilteredTotalUsers(response.data));
      } else {
        yield put(fetchTotalUsers(response.data));
      }
      callback && callback(response.data);
    }
    yield put(totalUsersFetchingComplete());
  } catch (error) {
    yield put(totalUsersFetchingComplete());
  }
}

function* initGetActiveUsers(action) {
  const { payload } = action || {};

  const { clientName = "" } = payload;
  yield put(fetchingActiveUsers());
  try {
    let url;
    if (clientName.toUpperCase() === AtcatAdminName) {
      url = ActiveUserList;
    } else {
      url = UserManagementGetActiveUsersList;
    }
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchActiveUsers(response.data));
    }
    yield put(activeUsersFetchingComplete());
  } catch (error) {
    yield put(activeUsersFetchingComplete());
  }
}

function* initGetInactiveUsers(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { payload } = action || {};
  yield put(fetchingInactiveUsers());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName: sessionDetails && sessionDetails.clientName,
      userName: sessionDetails && sessionDetails.name,
    });
    const response = yield call(axios, InactiveUserList, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchInactiveUsers(response.data));
    }
    yield put(inactiveUsersFetchingComplete());
  } catch (error) {
    yield put(inactiveUsersFetchingComplete());
  }
}

function* initGetUsersList(action) {
  const { payload } = action || {};
  const { clientName = "" } = payload;
  yield put(fetchingUsersList());
  try {
    let url;
    if (clientName.toUpperCase() === AtcatAdminName) {
      url = GetUserList;
    } else {
      url = UserManagementUserFunctionality;
    }
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });

    let response = yield call(axios, url, requestDetails);
    response = response.data;
    if (response === Error_Msg) {
      const notification = buildNotification({
        message: response,
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    } else if (response && response.length > 0) {
      yield put(fetchUsersList(response));
    } else {
      yield put(fetchUsersList([]));
    }
    yield put(usersListFetchingComplete());
  } catch (error) {
    yield put(usersListFetchingComplete());
  }
}

function* initGetUserLanguagesSaga() {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  yield put(userLanguagesFetching());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      clientName: sessionDetails && sessionDetails.clientName,
    });
    const response = yield call(axios, GetLanguages, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchUserLanguages(response.data));
    }
    yield put(userLanguagesFetchComplete());
  } catch (error) {
    yield put(userLanguagesFetchComplete());
  }
}

function* initUserActionSaga(action) {
  const { payload, callback } = action || {};
  const { clientName = "" } = payload;
  try {
    let url;
    if (clientName.toUpperCase() === AtcatAdminName) {
      url = ActionOnUser;
    } else {
      url = UserManagementActionsOnUser;
    }
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, url, requestDetails);
    response = response.data;
    if (response.resultStatus === true) {
      const message =
        payload.command === "Delete"
          ? `atcat.userDeletedSuccesfully`
          : payload.command === "Activate"
          ? `atcat.userActivateSuccessfully`
          : payload.command === "Deactivate"
          ? `atcat.userDeactivateSuccessfully`
          : `atcat.userUnlockSuccessfully`;
      yield put(userActionSuccess());
      generateNotification(message, "success");
      callback && callback();
    } else {
      const notification = buildNotification({
        message: response.message,
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    yield put(userActionFailed());
    const notification = buildNotification({
      message: error,
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}
function* initSaveUserDataSaga(action) {
  const { payload, callback } = action || {};
  const { clientName = "" } = payload;

  if (payload.UserName !== undefined) {
    if (payload.Email === payload.ApproverEmail) {
      generateNotification("atcat.emailAndApproverEmailCannotBeSame", "danger");
    } else {
      const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
      try {
        let url;
        if (clientName.toUpperCase() === AtcatAdminName) {
          url = payload && payload.UserID ? UpdateUserDetails : AddNewUser;
        } else {
          url =
            payload && payload.UserID
              ? UserManagementUpdateUserDetails
              : UserManagementAddNewUser;
        }
        const requestDetails = postRequestDetails();
        let updatedPayload = {
          ...payload,
        };
        if (payload && payload.UserID) {
          updatedPayload = {
            ...updatedPayload,
            LoggedUser: sessionDetails && sessionDetails.userName,
          };
        }
        yield (requestDetails.data = {
          ...updatedPayload,
        });
        let response = yield call(axios, url, requestDetails);
        response = response.data;
        if (response.resultStatus === true) {
          yield put(userDataSaveSuccess());
          generateNotification(
            generateKey(response.resultSet.message),
            "success"
          );
          callback && callback();
        } else {
          response.resultSet.message.forEach((item) => {
            generateNotification(generateKey(item), "danger");
          });
        }
      } catch (error) {
        yield put(userDataSaveFailed());
      }
    }
  } else {
    if (payload.userDetail.Email === payload.userDetail.ApproverEmail) {
      generateNotification("atcat.emailAndApproverEmailCannotBeSame", "danger");
    } else {
      try {
        let url;
        if (clientName.toUpperCase() === AtcatAdminName) {
          url =
            payload && payload.userDetail.UserID
              ? UpdateUserDetails
              : AddNewUser;
        } else {
          url =
            payload && payload.userDetail.UserID
              ? UserManagementUpdateUserDetails
              : UserManagementAddNewUser;
        }
        const requestDetails = postRequestDetails();
        let updatedPayload = {
          ...payload,
        };
        if (payload && payload.userDetail.UserID) {
          updatedPayload = {
            ...updatedPayload,
          };
        }
        yield (requestDetails.data = {
          ...updatedPayload,
        });
        let response = yield call(axios, url, requestDetails);
        response = response.data;
        if (response === "Updated selected user successfully!!") {
          yield put(userDataSaveSuccess());
          generateNotification(
            "atcat.updatedSelectedUserSuccessfully",
            "success"
          );
          callback && callback();
        } else {
          let type = "danger",
            msg = response.split("<br>");
          msg.forEach((item) => {
            if (item !== "") {
              generateNotification(generateKey(item), type);
            }
          });
        }
      } catch (error) {
        yield put(userDataSaveFailed());
      }
    }
  }
}

function* initGetCountryListSaga(action) {
  const { payload } = action || {};
  const { clientName = "" } = payload;
  yield put(fetchingCountryList());
  try {
    let url;
    if (clientName.toUpperCase() === AtcatAdminName) {
      url = GetCountryList;
    } else {
      url = UserManagementGetCountryList;
    }
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      const data =
        response.data && response.data.length
          ? response.data.map((country) => ({
              ...country,
              id: country.CountryID,
              value: country.CountryName,
            }))
          : [];
      yield put(fetchCountryList(data));
    }
    yield put(countryListFetchingComplete());
  } catch (error) {
    yield put(countryListFetchingComplete());
  }
}

function* initGetClientListSaga(action) {
  const { payload } = action || {};
  yield put(fetchingClientList());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, ClientMClientList, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchClientList(response.data));
    }
    yield put(clientListFetchingComplete());
  } catch (error) {
    yield put(clientListFetchingComplete());
  }
}

function* initGetUserManagementHeaderList(action){
  const { payload,callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, GetUserManagementHeaderList, requestDetails);
    if (response && response.status === 200 && response.data!=="Request could not be processed at this time. Please contact support team.") {
      yield put(getUserManagementHeaderList(response.data));
    }
    else{
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    }
    callback && callback();
  } catch (error) {
    yield put(getUserManagementHeaderList([]));
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

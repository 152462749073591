import { CURRENT_ROUTE_SET, INIT_SET_CURRENT_ROUTE,INITIATE_EXPORT_TEMPLATE } from "./actionTypes";

export const initSetRoute = (payload) => {
  return {
    type: INIT_SET_CURRENT_ROUTE,
    payload,
  };
};

export const setRoute = (data) => {
  return {
    type: CURRENT_ROUTE_SET,
    data,
  };
};

export const initiateExportTemplate = (payload) => {
  return {
    type: INITIATE_EXPORT_TEMPLATE,
    payload,
  };
};

export const APListCount = "api/AccountsPayable/APListCount";
export const APGetAccountPayableList =
  "api/AccountsPayable/GetAccountsPayableList";
export const APAddAdvanceFilter = "api/AccountsPayable/AddAdvancedFilter";
export const APGetEditFilterData = "api/AccountsPayable/GetEditFilterPageData";
export const APDeleteAdvanceFilter = "api/AccountsPayable/DeleteAdvancedFilter";
export const APGetFilterName = "api/AccountsPayable/GetFilterName";
export const APGetFilterFields = "api/AccountsPayable/GetFilterFields";
export const APExportToExcel = "api/AccountsPayable/ExportToExcel";
export const APExportAllToExcel = "api/AccountsPayable/ExportAllToExcel";
export const APPEditPreProcessingCriteria =
  "api/AdditionalPreProcCriteria/EditPrePostCriteria";
export const PasswordManagementForgotPassword =
  "api/PasswordManagement/ForgotPassword";
export const AddNewClient = "api/AddNewClient/AddClient";
export const GetFooterDetails = "api/loginLogout/GetFooterValue";

export const GenerateOTP = "api/loginLogout/GenerateOTP";
export const AdhocExcelRecordCount = "api/AdhocDups/GetRecordCount";
export const AdhocExcelValidation = "api/AdhocDups/ExcelValidation";
export const SubmitAdhoc = "api/AdhocDups/SubmitAdHocDups";
export const AdhocDupsJobs = "api/AdhocDups/Jobs";
export const AdhocDupsJobDetails = "api/AdhocDups/JobDetails";
export const AdhocExportToExcel = "api/AdhocDups/ExportToExcel";
export const GetCountryList = "api/AdminUserManagement/GetCountryList";
export const AtcatUserCount = "api/AdminUserManagement/GetAtcatUserCount";
export const ATCATClientCount = "api/AdminUserManagement/GetAtcatClientCount";
export const GetUserList = "api/AdminUserManagement/GetUsersList";
export const TotalClientUserList = "api/AdminUserManagement/GetTotalUsersList";
export const AddNewUser = "api/AdminUserManagement/AddNewUser";
export const ActiveUserList = "api/AdminUserManagement/GetActiveUsersList";
export const LockedUserList = "api/AdminUserManagement/GetLockedUsersList";
export const InactiveUserList = "api/AdminUserManagement/GetInactiveUsersList";
export const ActionOnUser = "api/AdminUserManagement/ActionsOnUsers";
export const GetUserManagementHeaderList= "api/UserManagement/GetHeaderList";
export const UpdateUserDetails = "api/AdminUserManagement/UpdateUserDetails";
export const TestMail = "api/AdminUserManagement/TestMail";
export const ResetPassword = "api/AdminUserManagement/ResetPassword";
export const GetClientAssigned = "api/AdminUserManagement/GetClientAssigned";
export const GetLanguages = "api/AdminUserManagement/GetLanguages";
export const AISubmitAdhoc = "api/AI_AdhocDups/SubmitAdHocDups";
export const ApplicationName = "api/loginLogout/GetLabelValue";
export const GetAIAdhocExportFileRecords =
  "api/AI_AdhocDups/GetAIAdhocExportFileRecords";
export const GetAIAdhocSelectedExportRecord =
  "api/AI_AdhocDups/GetAIAdhocSelectedExportRecords";
export const AIExportToExcel = "api/AI_AdhocDups/ExportToExcel";
export const AIDupsCount = "api/AI_ReVisitedDupsUnattended/DupsCount";
export const GetDupsType = "api/AI_ReVisitedDupsUnattended/GetDupsType";
export const GetInvestigationType =
  "api/AI_ReVisitedDupsUnattended/GetInvestigationType";
export const AIRevisitedUnattendedPost = "api/AI_ReVisitedDupsUnattended/Post";
export const SaveExceptionDetails =
  "api/AI_ReVisitedDupsUnattended/SaveExceptionDetails";
export const AddAdvanceFilter =
  "api/AI_ReVisitedDupsUnattended/AddAdvancedFilter";
export const AIRevisitedDupsEditFilterPageData =
  "api/AI_ReVisitedDupsUnattended/GetEditFilterPageData";
export const AIRevisitedDupsDeleteAdvanceFilter =
  "api/AI_ReVisitedDupsUnattended/DeleteAdvancedFilter";
export const AIRevisitedDupsGetFilterName =
  "api/AI_ReVisitedDupsUnattended/GetFilterName";
export const AIRevisitedDupsGetFilterFields =
  "api/AI_ReVisitedDupsUnattended/GetFilterFields";
export const AIRevisitedDupsRightAlignedFilterField =
  "api/AI_ReVisitedDupsUnattended/GetRightAlignedFields";
export const AIRevisitedDupsMassReleaseWithComment =
  "api/AI_ReVisitedDupsUnattended/MassReleaseWithComments";
export const AIRevisitedDupsExceptionList =
  "api/AI_ReVisitedDupsUnattended/GetExceptionList";
export const AIRevisitedDupsExceptionRecord =
  "api/AI_ReVisitedDupsUnattended/GetExceptionRecords";
export const AIRevisitedDupsMoveAndCopyToClaim =
  "api/AI_ReVisitedDupsUnattended/MoveAndCopyToClaim";
export const AIRevisitedDupsExportToExcel =
  "api/AI_ReVisitedDupsUnattended/ExportToExcel";
export const AIRevisitedDupsExportAllToExcel =
  "api/AI_ReVisitedDupsUnattended/DupsExportAllToExcel";
export const AIRevisitedDupsExceptionListHeader =
  "api/AI_ReVisitedDupsUnattended/GetDupsExceptionListHeaders";
export const AIRevisitedDupsExceptionFieldList =
  "api/AI_ReVisitedDupsUnattended/GetExportFieldsList";
export const AIRevisitedDupsGetExceptionListOnJobId =
  "api/AI_ReVisitedDupsUnattended/GetExceptionListOnJobId";
export const AIRevisitedDupsGetPrimasDFMURL =
  "api/AI_ReVisitedDupsUnattended/GetPrimusDFMURL";
export const AISavedFilterCount = "api/AI_SavedFilter/GetFilterCount";
export const AISavedAddAdvanceFilter = "api/AI_SavedFilter/AddAdvancedFilter";
export const AIFilterGetEditFilterPageData =
  "api/AI_SavedFilter/GetEditFilterPageData";
export const AISavedDeleteAdvanceFilter =
  "api/AI_SavedFilter/DeleteAdvancedFilter";
export const AISavedFilterName = "api/AI_SavedFilter/GetFilterName";
export const AISavedRunWithFilterSort = "api/AI_SavedFilter/RunFilterWithSort";
export const AISavedFilterFields = "api/AI_SavedFilter/GetFilterFields";
export const AISavedReleaseExceptionInExceptionQuery =
  "api/AI_SavedFilter/ReleaseExceptionsInExceptionQuery";
export const AISavedEditFilter = "api/AI_SavedFilter/EditFilter";
export const AISavedDuplicateExportToExcel =
  "api/AI_SavedFilter/DuplicateExportToExcel";
export const AISavedDuplicateExportAllToExcel =
  "api/AI_SavedFilter/DuplicateExportAllToExcel";
export const AIReportAIReasonVolDD = "api/AIReportsController/AIReasonsVolDD";
export const AIReportAIReasonVolEF = "api/AIReportsController/AIReasonsVolEF";
export const AIReportAITrueErrorDD = "api/AIReportsController/AITrueEErrorDD";
export const AIReportTrueErrorCoverageEF =
  "api/AIReportsController/GetAITrueErrorCoverageEF";
export const AIReportVolumeDistributionPercentile =
  "api/AIReportsController/GetAIVolDistributionPercentile";
export const AIVolumeDistributionPercentileEF =
  "api/AIReportsController/GetAIVolDistributionPercentileEF";
export const AlertGetAlertList = "api/AlertsManagement/GetAlertsList";
export const AlertGetAlertDetails = "api/AlertsManagement/GetAlertDetails";
export const AlertGetClientAlertList =
  "api/AlertsManagement/GetClientAlertsList";
export const AlertGetClientAlertDetails =
  "api/AlertsManagement/GetClientAlertsDetails";
export const AlertSaveClientAlertDetails =
  "api/AlertsManagement/SaveClientAlertDetails";
export const AlertSaveAlertDetails = "api/AlertsManagement/SaveAlertDetails";
export const APPHListCount = "api/AP_PH/APPHListCount";
export const APPHList = "api/AP_PH/GetAP_PHList";
export const APPHAddAdvanceFilter = "api/AP_PH/AddAdvancedFilter";
export const APPHEditFilterPageData = "api/AP_PH/GetEditFilterPageData";
export const APPHDeleteAdvanceFilter = "api/AP_PH/DeleteAdvancedFilter";
export const APPHGetFilterName = "api/AP_PH/GetFilterName";
export const APPHGetFilterFields = "api/AP_PH/GetFilterFields";
export const APPHExportToExcel = "api/AP_PH/ExportToExcel";
export const APPHExportAllToExcel = "api/AP_PH/ExportAllToExcel";
export const BulkReleaseUpload = "api/BulkRelease/Upload";
export const BulkReleaseFraudAndErrorUpload =
  "api/BulkRelease/FraudAndErrorUpload";
export const BulkReleaseSubmitTime = "api/BulkRelease/SubmitTime";
export const BulkReleaseGetTime = "api/BulkRelease/GetTime";
export const BulkReleaseUploadFile = "api/BulkRelease/UploadFile";
export const BulkReleaseFraudAndErrorUploadFile =
  "api/BulkRelease/FraudAndErrorUploadFile";
export const BulkReleaseReleaseAll = "api/BulkRelease/ReleaseAll";
export const BulkReleaseFraudErrorReleaseAll =
  "api/BulkRelease/FraudAndErrorReleaseAll";
export const ChangePassword = "api/ChangePassword";
export const ChatBotEncrypt = "api/Chatbot/EncryptParam/api/ChatBot/Encrypt";
export const ClientDashboardStartDateEndDateUI =
  "api/ClientDashboard/CalculateStartEndDateForUI";
export const ClientDashbaordGetDashboardData =
  "api/ClientDashboard/GetDashboardData";
export const ClientDashboardClaimAgeingByOption =
  "api/ClientDashboard/GetClaimAgeingByOption";
export const ClientDashboardGetAuditorData =
  "api/ClientDashboard/GetAuditorData";
export const ClientDashboardAgeingByOption =
  "api/ClientDashboard/GetAgeingByOption";
export const ClientDashboardSequenceByOption =
  "api/ClientDashboard/GetSequenceByOption";
export const ClientDashboardSupplierByOption =
  "api/ClientDashboard/GetSupplierByOption";
export const ClientDashboardSupplier2ByOption =
  "api/ClientDashboard/GetSupplier2ByOption";
export const ClientDashboardAuditorList = "api/ClientDashboard/GetAuditorsList";
export const ClientDashboardMobileURL = "api/ClientDashboard/GetMobileURL";
export const ClientDashboardCon1 = "api/ClientDashboard/con1";
export const ClientDashboardConnectionDecrypt =
  "api/ClientDashboard/connectiondecrypt";
export const ClientMClientFunctionality =
  "api/ClientManagement/ClientFunctionality";
export const ClientMDeleteClient = "api/ClientManagement/DeleteClient";
export const ClientMTotalClient = "api/ClientManagement/TotalClients";
export const ClientMActiveClient = "api/ClientManagement/ActiveClients";
export const ClientMInactiveClient = "api/ClientManagement/InactiveClients";
export const ClientMClientDetailsByName =
  "api/ClientManagement/GetClientDetailsByName";
export const ClientMActionOnClient = "api/ClientManagement/ActionsOnClients";
export const ClientMClientList = "api/ClientManagement/GetClientList";
export const ClientMSwitchClient = "api/ClientManagement/SwitchClient";
export const ClientMDefaultValueOnClickofAddClient =
  "api/ClientManagement/DefaultValuesOnClickOfAddClient";
export const ClientMGetCountryList = "api/ClientManagement/GetCountryList";
export const ClientMAtcatVersionList =
  "api/ClientManagement/GetATCATVersionList";
export const ClientMUpdateClientProfile =
  "api/ClientManagement/UpdateClientProfile";
export const ClientMGetValidClientList =
  "api/ClientManagement/GetValidClientList";
export const ClientMGetClientLanguage =
  "api/ClientManagement/GetClientLanguages";
export const ClientMGetResponse = "api/ClientManagement/GetResponse";
export const CreateBasicToken = "api/createBasicToken";
export const ClientMGetClientAuthenticationDetails =
  "api/ClientManagement/GetClientAuthenticationDetails";
export const ClientMGetClientTokenDetails =
  "api/ClientManagement/GetClientTokenDetails";
export const ClientSystemParamGetClientSystemParameter =
  "api/ClientSystemParameters/GetClientSystemParameters";
export const ClientSystemParamSaveClientSystemParameter =
  "api/ClientSystemParameters/SaveClientSystemParameters";
export const ClientUDLGetUDLListData =
  "api/ClientUDLMaintenance/GetUDLDataList";
export const ClientUDLGetUDLDetails = "api/ClientUDLMaintenance/GetUDLDetails";
export const ClientUDLSaveUDLDetails =
  "api/ClientUDLMaintenance/SaveUDLDetails";
export const CommonReportClientDetails =
  "api/CommonController/GetReportDataClientDetails";
export const CommonReportClientLogo =
  "api/CommonController/GetReportDataClientDetailsLogo";
export const CompletedJobsViewJobDetails = "api/CompletedJobs/ViewJobDetails";
export const CompletedJobsViewJobListing = "api/CompletedJobs/ViewJobListing";
export const CompletedJobsClientQueueSummary =
  "api/CompletedJobs/ClientQueueSummary";
export const CompletedJobsAddAdvanceFilter =
  "api/CompletedJobs/AddAdvancedFilter";
export const completedJobsGetEditFilterFields =
  "api/CompletedJobs/GetFilterFields";
export const CompletedJobsGetEditFilterPageData =
  "api/CompletedJobs/GetEditFilterPageData";
export const CompletedJobDeleteAdvanceFilter =
  "api/CompletedJobs/DeleteAdvancedFilter";
export const CompletedJobGetFilterName = "api/CompletedJobs/GetFilterName";
export const CompletedJobsCount = "api/CompletedJobs/GetCount";
export const CriteriaFieldsGetCriteriaFields =
  "api/CriteriaFields/GetCriteriaFields";
export const CriteriaFieldsGetSequenceFieldsEachSeq =
  "api/CriteriaFields/GetSequenceFieldsEachSequence";
export const CriteriaFieldsUpdateSequenceFields =
  "api/CriteriaFields/UpdateSequenceFields";
export const DashboardReportInvoiceCountRecoverd =
  "api/DashBoardReportController/GetInvoiceCountRecovered";
export const DashboardReportExceptionMatrixSeqDashboard =
  "api/DashBoardReportController/GetExceptMetrixSeqDashboard";
export const DashbordReportGetReportingCurrency =
  "api/DashBoardReportController/GetReportingCurrency";
export const DashboardReportAuditorLogData =
  "api/DashBoardReportController/GetAuditorLogDataDashboard";
export const DashboardReportGetReportDataGlobalSettings =
  "api/DashBoardReportController/GetReportDataGlobalSettingsForParameterValues";
export const DashboardReportSupplierAnalysisDataDrillDown =
  "api/DashBoardReportController/GetSupplierAnalysisDataDrillDown";
export const DashboardReportGetSupplierClaimDataDrillDown =
  "api/DashBoardReportController/GetSupplierClaimDataDrillDown";
export const DataValidationGetValidationProcessRecords =
  "api/DataValidationReportsController/GetValidationProcessedRecords";
export const DataValidationVendorTaxValidationView =
  "api/DataValidationReportsController/VendorTaxValidationView";
export const DairyGetDairyDetails = "api/Diary/GetDiaryDetails";
export const DairyGetReportDataDairyList = "api/Diary/GetReportDataDiaryList";
export const DairyAddDairy = "api/Diary/AddDiary";
export const DairyUpdateDairy = "api/Diary/UpdateDiary";
export const DairyUpdateCompletedFlag = "api/Diary/UpdateCompleteFlag";
export const DairyUpdateUnCompletedFlag = "api/Diary/UpdateUnCompleteFlag";
export const DuplicateClaimListOfDuplicateClaim =
  "api/DuplicateClaim/GetListOfDuplicateClaims";
export const DuplicateClaimAddAdvanceFilter =
  "api/DuplicateClaim/AddAdvancedFilter";
export const DuplicateClaimEditFilterPageData =
  "api/DuplicateClaim/GetEditFilterPageData";
export const DuplicateClaimDeleteAdvanceFilter =
  "api/DuplicateClaim/DeleteAdvancedFilter";
export const DuplicateClaimFilterName = "api/DuplicateClaim/GetFilterName";
export const DuplicateClaimFilterFields = "api/DuplicateClaim/GetFilterFields";
export const DuplicateClaimExportToExcel =
  "api/DuplicateClaim/DuplicateCliamsExportToExcel";
export const DuplicateClaimExportAllToExcel =
  "api/DuplicateClaim/DuplicateCliamsExportAllToExcel";
export const EncryptedUrlParameterEncryptParam =
  "api/EncryptUrlParameter/EncryptParam/api/EncryptUrlParameter/EncryptParam";
export const EncryptedUrlParameterDecrptyParam =
  "api/EncryptUrlParameter/DecryptParam/api/EncryptUrlParameter/DecryptParam";
export const GDPR = "api/GDPR";
export const GDPRMessage = "api/GDPRClientMessage";
export const GeneralReportManualClaimCollectionAnalysis =
  "api/GeneralReportController/GetManualClaimsCollectionsAnalysis";
export const GeneralReportDataloadFileType =
  "api/GeneralReportController/GetDataLoadByFileType";
export const GeneralReportAuditorProductivityDetails =
  "api/GeneralReportController/GetAuditorProductivityDetailedAction";
export const GeneralReportAuditorClaimProductivity =
  "api/GeneralReportController/GetAuditorClaimProductivity";
export const GeneralReportCompanyCodeSpendReport =
  "api/GeneralReportController/GetCompanyCodeSpendReport";
export const GeneralReportDataOffsetReport =
  "api/GeneralReportController/GetDataOffsetDetails";
export const GeneralReportUnattendedDupsClaimByValue =
  "api/GeneralReportController/GetReportDataUnattDupsClaimsByValue";
export const GeneralReportDataExcetionMatrix =
  "api/GeneralReportController/GetReportDataExceptionMetrics";
export const GeneralReportExceptionMatrixBySeq =
  "api/GeneralReportController/GetExceptionMetricsBySeq";
export const GeneralReportClaimCreationSummery =
  "api/GeneralReportController/GetClaimCreationSummary";
export const GeneralReportDataUnattDupsCollectionAnalysis =
  "api/GeneralReportController/GetReportDataUnattDupsCollectionsAnalysis";
export const GeneralReportDataUnattDupsRootCauseAnalysis =
  "api/GeneralReportController/GetReportDataUnattDupsRootCauseAnalysis";
export const GeneralReportExceptionMatrixByUser =
  "api/GeneralReportController/GetExceptionMatrixByUser";
export const GeneralReportDataCallBackDairyReport =
  "api/GeneralReportController/GetDataCallBackDiaryReport";
export const GeneralReportManualClaimCategoryAnalysis =
  "api/GeneralReportController/GetManualClaimsByCategoryAnalysis";
export const GeneralReportVendorSpendReport =
  "api/GeneralReportController/GetVendorSpendReport";
export const GeneralReportPayamentVolumeAndValueByPaymentYear =
  "api/GeneralReportController/GetPaymentVolumeAndValueByPaymentYear";
export const GeneralReportAuditorExceptionMatrix =
  "api/GeneralReportController/GetAuditorExceptionMetrics";
export const GeneralReportGlobalSettingsForParameterValue =
  "api/GeneralReportController/GetReportDataGlobalSettingsForParameterValues";
export const GeneralReportStratificationOfInvoiceValueByYear =
  "api/GeneralReportController/GetStratificationOfInvoiceValueByYear";
export const GeneralReportSupplierWithRelativeSizeFactors =
  "api/GeneralReportController/GetSuppliersWithRelativeSizeFactorsPossiblyDueToMisplacedDecimals";
export const GenralReportTransactionVolumeValue =
  "api/GeneralReportController/GetTransactionVolumeInvoiceValue";
export const GeneralReportAverageInvoiceValue =
  "api/GeneralReportController/GetAverageInvoiceValue";
export const GeneralReportSupplierLargeVolLowValData =
  "api/GeneralReportController/GetSuppliersLargeVolmLowValData";
export const GeneralReportTransVolumePaymentValue =
  "api/GeneralReportController/GetTransVolumeAndPaymentValue";
export const GeneralReportAuditorProductivityReport =
  "api/GeneralReportController/GetAuditorProductivityReport";
export const GeneralReportCallBackDairy =
  "api/GeneralReportController/GetDataCallBackDiary";
export const HistoryCount = "api/History/GetHistoryCount";
export const HistoryData = "api/History/GetHistoryData";
export const IPQCUpload = "api/IPQC/IPQCUpload";
export const IPQCReleaseAll = "api/IPQC/IPQCReleaseAll";
export const IPQCUploadFile = "api/IPQC/IPQCUploadFile";
export const MoveAndCopyToClaim =
  "api/RevisitedDupsUnattended/MoveAndCopyToClaim";
export const JobSchedularListofAllScheduleJob =
  "api/JobScheduler/GetListOfAllScheduledJobs";
export const JobSchedularGetScheduledJobDetails =
  "api/JobScheduler/GetScheduledJobDetails";
export const JobSchedularUpdateScheduledJobDetails =
  "api/JobScheduler/UpdateScheduledJobDetails";
export const JobSchedularAddScheduledJob = "api/JobScheduler/AddScheduledJob";
export const JobSchedularDeleteScheduledJob =
  "api/JobScheduler/DeleteScheduledJob";
export const JobSchedularSubmitFunctionalityDups =
  "api/JobScheduler/SubmitJobFunctionalityDups";
export const PasswordMangementGenerateOTP =
  "api/PasswordManagement/GenerateOTP";
export const JobSchedularLogsForDupsAndDataload =
  "api/JobScheduler/GetLogsForDupsAndDataload";
export const JobSchedularServerTime = "api/JobScheduler/GetServerDateTime";
export const LoginLogout = "api/LoginLogout";
export const NonSSOLoginLogout = "api/NonSSOLoginLogout";
export const LoginLogoutLanguages = "api/loginLogout/GetLanguages";
export const LoginLogoutUpdateLanguages = "api/loginLogout/UpdateLanguage";
export const LoginLogoutSelectedLanguage =
  "api/loginLogout/GetSelectedLanguage";
export const GenerateToken = "api/GenerateToken";
export const loginLogoutNonSSO = "api/loginLogout/NonSSOPost";
export const loginLogoutSSO = "api/loginLogout/SSOPost";
export const LoginLogoutRenewToken = "api/loginLogout/GetRenewToken";
export const ManageGridCategoryListing =
  "api/ManageGrids/GetGridCategoryListing";
export const ManageGridListing = "api/ManageGrids/GetGridListing";
export const ManageGridDetails = "api/ManageGrids/GetGridDetails";
export const ManageGridUpdateGridDetails = "api/ManageGrids/UpdateGridDetails";
export const ManageVendorGroupsFilterDropDown =
  "api/ManageVendorGroups/GetFilterDropDown";
export const ManageVendorGroupsListofVendorGroups =
  "api/ManageVendorGroups/GetListOfVendorGroups";
export const ManageVendorGroupsCreateVendorGroup =
  "api/ManageVendorGroups/CreateVendorGroup";
export const ManageVendorGroupsDetailsofVendorGroup =
  "api/ManageVendorGroups/GetDetailsofVendorGroup";
export const ManageVendorGroupSaveDetailsofVendorGroup =
  "api/ManageVendorGroups/SaveDetailsOfvendorGroup";
export const ManageVendorGroupDeleteVendorGroup =
  "api/ManageVendorGroups/DeleteVendorGroup";
export const ManageVendorGroupUnassignedVendorForVendorGroup =
  "api/ManageVendorGroups/UnassignVendorsForVendorGroup";
export const ManageVendorGroupOnclickOfAssignVendors =
  "api/ManageVendorGroups/OnClickOfAssignVendors";
export const ManageVendorAssignVendorsToGroup =
  "api/ManageVendorGroups/AssignVendorsToGroup";
export const ManageVendorVendorList = "api/ManageVendors/GetVendorList";
export const ManageVendorAddAdvanceFilter =
  "api/ManageVendors/AddAdvancedFilter";
export const ManageVendorEditFilterPageData =
  "api/ManageVendors/GetEditFilterPageData";
export const ManageVendorDeleteAdvanceFilter =
  "api/ManageVendors/DeleteAdvancedFilter";
export const ManageVendorFilterName = "api/ManageVendors/GetFilterName";
export const ManageVendorFilterFields = "api/ManageVendors/GetFilterFields";
export const ManageVendorExportToExcel = "api/ManageVendors/ExportToExcel";
export const ManageVendorExportAllToExcel =
  "api/ManageVendors/ExportAllToExcel";
export const ManageVendorDetailsOfVendor =
  "api/ManageVendors/GetDetailsOfVendor";
export const ManageVendorSaveDetailsVendor =
  "api/ManageVendors/SaveDetailsOfVendor";
export const ManageVendorAddVendorContactGroup =
  "api/ManageVendors/AddVendorContactDetails";
export const ManageVendorVendorContactGroup =
  "api/ManageVendors/GetVendorContactDetails";
export const ManageVendorUpdateVendorContactDetails =
  "api/ManageVendors/UpdateVendorContactDetails";
export const MangeVendorDeleteVendorContactDetails =
  "api/ManageVendors/DeleteVendorContactDetails";
export const ManageVendorActionOnVendor = "api/ManageVendors/ActionOnVendors";
export const ManualClaimListOfManualClaims =
  "api/ManualClaims/GetListOfManualClaims";
export const ManualClaimFilterData = "api/ManualClaims/GetFilterData";
export const ManualClaimDetailsOfEachClaim =
  "api/ManualClaims/DetailsOfEachClaim";
export const ManualClaimSaveClaimDetails = "api/ManualClaims/SaveClaimDetails";
export const ManualClaimSaveCopyClaimDetails =
  "api/ManualClaims/SaveCopyClaimDetails";
export const ManualClaimDetailsOfAddManualClaim =
  "api/ManualClaims/GetDetailsForAddManualClaims";
export const ManualClaimAddManualClaim = "api/ManualClaims/AddManualClaims";
export const ManualClaimSaveCopyForManualClaim =
  "api/ManualClaims/SaveCopyForAddManualClaims";
export const ManualClaimAPPHListing = "api/ManualClaims/GetAPPHListing";
export const ManualClaimAssignAndCalculateAPPHRecord =
  "api/ManualClaims/AssignAndCalculateAPPHRecords";
export const ManualClaimAssignAPPHRecord = "api/ManualClaims/AssignAPPHRecords";
export const ManualClaimAddOffset = "api/ManualClaims/AddOffset";
export const ManualClaimUpdateOffset = "api/ManualClaims/UpdateOffset";
export const ManualClaimDeleteOffset = "api/ManualClaims/DeleteOffset";
export const ManualClaimGetPaybackRecord = "api/ManualClaims/GetPaybackRecords";
export const ManualClaimPaybackDetails = "api/ManualClaims/GetPaybackDetails";
export const ManualClaimAddPayback = "api/ManualClaims/AddPayback";
export const ManualClaimGetOffsetRecord = "api/ManualClaims/GetOffsetRecords";
export const ManualClaimGetOffsetDetails = "api/ManualClaims/GetOffsetDetails";
export const ManualClaimManualClaimHistory =
  "api/ManualClaims/GetManualClaimsHistory";
export const ManualClaimAddDocument = "api/ManualClaims/AddDocument";
export const ManualClaimDocumentList = "api/ManualClaims/GetDocumentsList";
export const ManualClaimDocumentDetails = "api/ManualClaims/GetDocumentDetails";
export const ManualClaimUpdateDocument = "api/ManualClaims/UpdateDocument";
export const ManualClaimDeleteDocument = "api/ManualClaims/DeleteDocument";
export const ManualClaimSearchClaimId = "api/ManualClaims/SearchClaimId";
export const ManualClaimRecordDetails = "api/ManualClaims/RecordDetails";
export const ManualClaimActionOnManualClaimRecord =
  "api/ManualClaims/ActionsOnManualClaimRecords";
export const ManualClaimModifiedManualClaim =
  "api/ManualClaims/ModifiedManualClaim";
export const ManualClaimCalculateClaimValue =
  "api/ManualClaims/CalculateClaimValues";
export const ManualClaimExportToExcel = "api/ManualClaims/ExportToExcel";
export const ManualClaimExportAllToExcel = "api/ManualClaims/ExportAllToExcel";
export const ManualDataloadDupsUploadPH = "api/ManualDataloadDups/UploadPH";
export const ManualDataloadDupsUploadVoid = "api/ManualDataloadDups/UploadVoid";
export const ManualDataloadDupsUploadAP = "api/ManualDataloadDups/UploadAP";
export const ManualDataloadDupsRunDataload =
  "api/ManualDataloadDups/RunDataloadAPI";
export const ManualDataloadDupsDataloadInfo =
  "api/ManualDataloadDups/GetDataloadInfo";
export const ManualDataloadDupsDupsInfo = "api/ManualDataloadDups/GetDupsInfo";
export const ManualDataloadDupsRunDups = "api/ManualDataloadDups/RunDupsAPI";
export const PasswordManagementGetPassword =
  "api/PasswordManagement/GetPassword";
export const PasswordManagementUpdatePassword =
  "api/PasswordManagement/UpdatePassword";
export const PasswordManagementCheckPasswordConfiguration =
  "api/PasswordManagement/CheckPasswordConfiguration";
export const PHListingPHList = "api/PaymentHistoryListing/GetPHList";
export const PHListingPHListCount = "api/PaymentHistoryListing/GetPHListCount";
export const PHListingAddAdvanceFilter =
  "api/PaymentHistoryListing/AddAdvancedFilter";
export const PHListingFilterFields =
  "api/PaymentHistoryListing/GetFilterFields";
export const PHListingFilterName = "api/PaymentHistoryListing/GetFilterName";
export const PHListingDeleteAdvanceFilter =
  "api/PaymentHistoryListing/DeleteAdvancedFilter";
export const PHListingEditFilterPageData =
  "api/PaymentHistoryListing/GetEditFilterPageData";
export const PHListingExportToExcel = "api/PaymentHistoryListing/ExportToExcel";
export const PHListingExportAllToExcel =
  "api/PaymentHistoryListing/ExportAllToExcel";
export const PreviewListDetailsReportAccountPayableDetails =
  "api/PreviewListAndDetailsReportController/GetAccountsPayableDetails";
export const PreviewListDetailsReportDupsClaimPreviewList =
  "api/PreviewListAndDetailsReportController/GetDUPSClaimPreviewList";
export const PreviewListDetailsReportDataClaimHeaderDetails =
  "api/PreviewListAndDetailsReportController/GetReportDataClaimsHeaderDetails";
export const PreviewListDetailsReportDataDairyList =
  "api/PreviewListAndDetailsReportController/GetReportDataDiaryList";
export const PreviewListDetailsReportDataDocumentList =
  "api/PreviewListAndDetailsReportController/GetReportDataDocumentList";
export const PreviewListDetailsReportDataClaimDupsDetails =
  "api/PreviewListAndDetailsReportController/GetReportDataClaimsDupsDetails";
export const PreviewListDetailsReportDataClaimModifiedDetails =
  "api/PreviewListAndDetailsReportController/GetReportDataClaimsDupsModifiedDetails";
export const PreviewListDetailsReportDataClaimSubReportOffset =
  "api/PreviewListAndDetailsReportController/GetReportDataClaimsSubReportOffsets";
export const PreviewListDetailsReportDataClaimSubReportPayback =
  "api/PreviewListAndDetailsReportController/GetReportDataClaimsSubReportPayback";
export const PreviewListDetailsReportDataClaimDupsDistribution =
  "api/PreviewListAndDetailsReportController/GetReportDataClaimDUPSDistribution";
export const PreviewListDetailsReportDataClaimDistributionBaseRecord =
  "api/PreviewListAndDetailsReportController/GetReportDataClaimDUPSDistributionBaseRecord";
export const PreviewListDetailsDuplicateExceptionList =
  "api/PreviewListAndDetailsReportController/GetReportDataDuplicateExceptionListing";
export const PreviewListDetailsDuplicateExceptionDetails =
  "api/PreviewListAndDetailsReportController/GetReportDataDuplicateExceptionDetails";
export const PreviewListAndDetailsManualClaimsDoc =
  "api/PreviewListAndDetailsReportController/GetManualClaimsDoc";
export const PreviewListAndDetailsManualClaimsDetails =
  "api/PreviewListAndDetailsReportController/GetManualClaimsDetails";
export const PreviewListAndDetailsManualClaimsModified =
  "api/PreviewListAndDetailsReportController/GetManualClaimsModified";
export const PreviewListAndDetailsManualClaimsOffset =
  "api/PreviewListAndDetailsReportController/GetManualClaimsOffset";
export const PreviewListAndDetailsManualClaimsPayback =
  "api/PreviewListAndDetailsReportController/GetManualClaimsPayback";
export const PreviewListAndDetailsManualClaimsDairy =
  "api/PreviewListAndDetailsReportController/GetManualClaimsDairy";
export const PreviewListAndDetailsManualClaimsHeaders =
  "api/PreviewListAndDetailsReportController/GetManualClaimsHeaders";
export const PreviewListAndDetailsDataClaimDUPSDistribution =
  "api/PreviewListAndDetailsReportController/GetDataClaimDUPSDistribution";
export const PreviewListAndDetailsClaimDUPSDistributionBaseRecord =
  "api/PreviewListAndDetailsReportController/GetDataClaimDUPSDistributionBaseRecord";
export const PreviewListAndDetailsClaimDUPSDistributionDetails =
  "api/PreviewListAndDetailsReportController/GetDataClaimDUPSDistributionDetails";
export const PreviewListAndDetailsManualClaimDistribution =
  "api/PreviewListAndDetailsReportController/GetManualClaimDistribution";
export const PreviewListAndDetailsManualClaimDistributionBaseRecord =
  "api/PreviewListAndDetailsReportController/GetManualClaimDistributionBaseRecord";
export const PreviewListAndDetailstManualClaimDistributionDetails =
  "api/PreviewListAndDetailsReportController/GetManualClaimDistributionDetails";
export const PreviewListAndDetailsDupsExceptionSetDetailsHeader =
  "api/PreviewListAndDetailsReportController/GetDupsExceptionSetDetailsHeader";
export const PreviewListAndDetailsDataDiaryList =
  "api/PreviewListAndDetailsReportController/GetDataDiaryList";
export const PreviewListAndDetailsDataDocumentList =
  "api/PreviewListAndDetailsReportController/GetDataDocumentList";
export const PreviewListAndDetailsDataDuplicateClaimsList =
  "api/PreviewListAndDetailsReportController/GetReportDataDuplicateClaimsList";
export const PreviewListAndDetailsDataDuplicateClaimsDetails =
  "api/PreviewListAndDetailsReportController/GetReportDataDuplicateClaimsDetails";
export const PreviewListAndDetailsDataClaimManualDistributionDetails =
  "api/PreviewListAndDetailsReportController/GetReportDataClaimManualDistributionDetails";
export const PreviewListAndDetailsDataClaimManualDistributionBase =
  "api/PreviewListAndDetailsReportController/GetReportDataClaimManualDistributionBase";
export const PreviewListAndDetailsAccountsPayableList =
  "api/PreviewListAndDetailsReportController/GetAccountsPayableList";
export const PreviewListAndDetailsDataPaymentHistoryList =
  "api/PreviewListAndDetailsReportController/GetReportDataPaymentHistoryList";
export const PreviewListAndDetailsPaymentHistoryDetails =
  "api/PreviewListAndDetailsReportController/GetPaymentHistoryDetails";
export const PreviewListAndDetailsDupsExceptionSetDetails =
  "api/PreviewListAndDetailsReportController/GetDupsExceptionSetDetails";
export const ProcessingCriteriaSequenceFieldDetails =
  "api/ProcessingCriteria/GetSequenceFieldDetails";
export const ProcessingCriteriaPost = "api/ProcessingCriteria/Post";
export const ProcessingCriteriaDelete = "api/ProcessingCriteria/Delete";
export const ReOrderGetProcessing = "api/ReOrder/GetProcessing";
export const ReOrderPostReoder = "api/ReOrder/PostReoder";
export const ReportGetParam = "api/Report/GetParam";
export const ReportManagementGetReportList =
  "api/ReportManagement/GetReportList";
export const ReportManagementSavedReportsList =
  "api/ReportManagement/GetSavedReportsList";
export const ReportManagementUploadReports =
  "api/ReportManagement/UploadReports";
export const ReportManagementVendorAnalysisReportList =
  "api/ReportManagement/GetVendorAnalysisReportList";
export const ReportManagementDataValidationReports =
  "api/ReportManagement/GetDataValidationReports";
export const RevisitedClaimsClaimsCount = "api/RevisitedClaims/ClaimsCount";
export const RevisitedClaimsVendorCount = "api/RevisitedClaims/VendorCount";
export const RevisitedClaimsVendorListCount =
  "api/RevisitedClaims/GetVendorListCount";
export const RevisitedClaimsVendorCountFromException =
  "api/RevisitedClaims/VendorCountFromException";
export const RevisitedClaimsVendorListFromexceptionCount =
  "api/RevisitedClaims/GetVendorListFromexceptionCount";
export const RevisitedClaimsGetOffsetRecords =
  "api/RevisitedClaims/GetOffsetRecords";
export const RevisitedClaimsActionsOnClaimRecords =
  "api/RevisitedClaims/ActionsOnClaimRecords";
export const RevisitedClaimsPost = "api/RevisitedClaims/Post";
export const ReVisitedClaimsClaimList = "api/ReVisitedClaims/GetClaimList";
export const RevisitedClaimsClaimRecords =
  "api/RevisitedClaims/GetClaimRecords";
export const RevisitedClaimsClaimCount = "api/RevisitedClaims/GetClaimCount";
export const RevisitedClaimsClaimFilterFields =
  "api/RevisitedClaims/GetClaimFilterFields";
export const RevisitedClaimsVendorFilterFields =
  "api/RevisitedClaims/GetVendorFilterFields";
export const ReVisitedClaimsSaveClaimDetails =
  "api/ReVisitedClaims/SaveClaimDetails";
export const ReVisitedClaimsOffsetDetails =
  "api/ReVisitedClaims/GetOffsetDetails";
export const ReVisitedClaimsOffsetClaimValue =
  "api/ReVisitedClaims/GetOffsetClaimValue";
export const ReVisitedClaimsAddOffset = "api/ReVisitedClaims/AddOffset";
export const ReVisitedClaimsDeleteOffset = "api/ReVisitedClaims/DeleteOffset";
export const ReVisitedClaimsUpdateOffset = "api/ReVisitedClaims/UpdateOffset";
export const ReVisitedClaimsAddPayback = "api/ReVisitedClaims/AddPayback";
export const ReVisitedClaimsPaybackDetails =
  "api/ReVisitedClaims/GetPaybackDetails";
export const ReVisitedClaimsPaybackRecords =
  "api/ReVisitedClaims/GetPaybackRecords";
export const ReVisitedClaimsAddDocument = "api/ReVisitedClaims/AddDocument";
export const ReVisitedClaimsDocumentsList =
  "api/ReVisitedClaims/GetDocumentsList";
export const ReVisitedClaimsDocumentDetails =
  "api/ReVisitedClaims/GetDocumentDetails";
export const ReVisitedClaimsUpdateDocument =
  "api/ReVisitedClaims/UpdateDocument";
export const ReVisitedClaimsDeleteDocument =
  "api/ReVisitedClaims/DeleteDocument";
export const ReVisitedClaimsVendorList = "api/ReVisitedClaims/GetVendorList";
export const ReVisitedClaimsVendorListData =
  "api/ReVisitedClaims/GetVendorListData";
export const ReVisitedClaimsVendorListDataFromException =
  "api/ReVisitedClaims/GetVendorListDataFromException";
export const ReVisitedClaimsVendorListFromExceptionset =
  "api/ReVisitedClaims/GetVendorListFromExceptionset";
export const ReVisitedClaimsExportToExcel = "api/ReVisitedClaims/ExportToExcel";
export const ReVisitedClaimsExportAllToExcel =
  "api/ReVisitedClaims/ExportAllToExcel";
export const ReVisitedClaimsSearchClaimId = "api/ReVisitedClaims/SearchClaimId";
export const RevisitedClaimsVendorLetters =
  "api/RevisitedClaims/GetVendorLetters";
export const ReVisitedDupsUnattendedDupsCount =
  "api/ReVisitedDupsUnattended/DupsCount";
export const ReVisitedDupsUnattendedAiFraudAndErrorCount =
  "api/ReVisitedDupsUnattended/AiFraudAndErrorCount";
export const ReVisitedDupsUnattendedDupsType =
  "api/ReVisitedDupsUnattended/GetDupsType";
export const ReVisitedDupsUnattendedInvestigationType =
  "api/ReVisitedDupsUnattended/GetInvestigationType";
export const ReVisitedDupsUnattendedFraudAndErrorFieldValues =
  "api/ReVisitedDupsUnattended/GetFraudAndErrorFieldValues";
export const ReVisitedDupsUnattendedPost = "api/ReVisitedDupsUnattended/Post";
export const ReVisitedDupsUnattendedSaveExceptionDetails =
  "api/ReVisitedDupsUnattended/SaveExceptionDetails";
export const ReVisitedDupsUnattendedSaveRecordDetails =
  "api/ReVisitedDupsUnattended/SaveRecordDetails";
export const ReVisitedDupsUnattendedAddAdvancedFilter =
  "api/ReVisitedDupsUnattended/AddAdvancedFilter";
export const ReVisitedDupsUnattendedEditFilterPageData =
  "api/ReVisitedDupsUnattended/GetEditFilterPageData";
export const ReVisitedDupsUnattendedDeleteAdvancedFilter =
  "api/ReVisitedDupsUnattended/DeleteAdvancedFilter";
export const ReVisitedDupsUnattendedFilterName =
  "api/ReVisitedDupsUnattended/GetFilterName";
export const ReVisitedDupsUnattendedFraudAndErrorFilterName =
  "api/ReVisitedDupsUnattended/GetFraudAndErrorFilterName";
export const ReVisitedDupsUnattendedFilterFields =
  "api/ReVisitedDupsUnattended/GetFilterFields";
export const ReVisitedDupsUnattendedRightAlignedFields =
  "api/ReVisitedDupsUnattended/GetRightAlignedFields";
export const ReVisitedDupsUnattendedMassReleaseWithComments =
  "api/ReVisitedDupsUnattended/MassReleaseWithComments";
export const ReVisitedDupsUnattendedExceptionList =
  "api/ReVisitedDupsUnattended/GetExceptionList";
export const ReVisitedDupsUnattendedFraudAndErrorDetectionData =
  "api/ReVisitedDupsUnattended/GetFraudAndErrorDetectionData";
export const ReVisitedDupsUnattendedExceptionRecords =
  "api/ReVisitedDupsUnattended/GetExceptionRecords";
export const ReVisitedDupsUnattendedMoveAndCopyToClaim =
  "api/ReVisitedDupsUnattended/MoveAndCopyToClaim";
export const ReVisitedDupsUnattendedExportToExcel =
  "api/ReVisitedDupsUnattended/ExportToExcel";
export const ReVisitedDupsUnattendedDupsExportAllToExcel =
  "api/ReVisitedDupsUnattended/DupsExportAllToExcel";
export const ReVisitedDupsUnattendedFraudAndErrorExportAllToExcel =
  "api/ReVisitedDupsUnattended/FraudAndErrorExportAllToExcel";
export const ReVisitedDupsUnattendedDupsExceptionListHeaders =
  "api/ReVisitedDupsUnattended/GetDupsExceptionListHeaders";
export const ReVisitedDupsUnattendedExportFieldsList =
  "api/ReVisitedDupsUnattended/GetExportFieldsList";
export const ReVisitedDupsUnattendedExceptionListOnJobId =
  "api/ReVisitedDupsUnattended/GetExceptionListOnJobId";
export const ReVisitedDupsUnattendedPrimusDFMURL =
  "api/ReVisitedDupsUnattended/GetPrimusDFMURL";
export const RunningJobsRunningJobsList = "api/RunningJobs/GetRunningJobsList";
export const RunningJobsjobDetails = "api/RunningJobs/GetRunningjobDetails";
export const RunningJobsJobsLog = "api/RunningJobs/GetRunningJobsLog";
export const RunningJobsKillRunningJob = "api/RunningJobs/KillRunningJob";
export const RunningJobsQueuedJobsList = "api/RunningJobs/GetQueuedJobsList";
export const RunningJobsSuspendQueue = "api/RunningJobs/SuspendQueue";
export const RunningJobsResumeQueue = "api/RunningJobs/ResumeQueue";
export const RunningJobsCheckSuspendState = "api/RunningJobs/CheckSuspendState";
export const RunningJobsDeleteQueue = "api/RunningJobs/DeleteQueue";
export const RunningJobsReorderJobRun = "api/RunningJobs/ReorderJobRun";
export const RunningJobsClientRunningJobsList =
  "api/RunningJobs/GetClientRunningJobsList";
export const RunningJobsClientQueuedJobsList =
  "api/RunningJobs/GetClientQueuedJobsList";
export const RunningJobsClientCompletedJobsList =
  "api/RunningJobs/GetClientCompletedJobsList";
export const RunningJobsCount = "api/RunningJobs/GetCount";
export const SavedFilterFilterCount = "api/SavedFilter/GetFilterCount";
export const SavedFilterAddAdvancedFilter = "api/SavedFilter/AddAdvancedFilter";
export const SavedFilterAddFraudAndErrorAdvancedFilter =
  "api/SavedFilter/AddFraudAndErrorAdvancedFilter";
export const SavedFilterEditFilterPageData =
  "api/SavedFilter/GetEditFilterPageData";
export const SavedFilterDeleteAdvancedFilter =
  "api/SavedFilter/DeleteAdvancedFilter";
export const SavedFilterFilterName = "api/SavedFilter/GetFilterName";
export const SavedFilterFraudAndErrorFilterName =
  "api/SavedFilter/GetFraudAndErrorFilterName";
export const SavedFilterRunFilterWithSort = "api/SavedFilter/RunFilterWithSort";
export const SavedFilterRunFraudAndErrorFilterWithSort =
  "api/SavedFilter/RunFraudAndErrorFilterWithSort";
export const SavedFilterFilterFields = "api/SavedFilter/GetFilterFields";
export const SavedFilterFraudAndErrorFilterFields =
  "api/SavedFilter/GetFraudAndErrorFilterFields";
export const SavedFilterReleaseExceptionsInExceptionQuery =
  "api/SavedFilter/ReleaseExceptionsInExceptionQuery";
export const SavedFilterEditFilter = "api/SavedFilter/EditFilter";
export const SavedFilterDuplicateExportToExcel =
  "api/SavedFilter/DuplicateExportToExcel";
export const SavedFilterDuplicateExportAllToExcel =
  "api/SavedFilter/DuplicateExportAllToExcel";
export const ssocallbackauth = "api/ssocallback/auth";
export const ssocallbackValidateTokenTime = "api/ssocallback/ValidateTokenTime";
export const ssocallbackValidateToken = "api/ssocallback/ValidateToken";
export const ssocallbackRelogin = "api/ssocallback/Relogin";
export const SupplementaryCriteriaGet = "api/SupplementaryCriteria/Get";
export const SupplementaryCriteriaPut = "api/SupplementaryCriteria/Put";
export const SystemParametersGetSystemParameters =
  "api/SystemParameters/GetSystemParameters";
export const SystemParametersSaveSystemParams =
  "api/SystemParameters/SaveSystemParams";
export const SystemParametersGetToUandPSDetails =
  "api/SystemParameters/GetToUandPSDetails";
export const SystemParametersSaveToUandPSDetails =
  "api/SystemParameters/SaveToUandPSDetails";
export const SystemParametersViewToUandPSStatement =
  "api/SystemParameters/ViewToUandPSStatement";
export const UDLMaintenanceGetUDLDataList = "api/UDLMaintenance/GetUDLDataList";
export const UDLMaintenanceGetUDLDetails = "api/UDLMaintenance/GetUDLDetails";
export const UDLMaintenanceSaveUDLDetails = "api/UDLMaintenance/SaveUDLDetails";
export const UserActionAuthenticationGetRoleBasedActions =
  "api/UserActionAuthentication/GetRoleBasedActions";
export const UserActionAuthenticationGetAdminRoleBasedActions =
  "api/UserActionAuthentication/GetAdminRoleBasedActions";
export const UserManagementGetCountryList = "api/UserManagement/GetCountryList";
export const UserManagementGetActiveUsersList =
  "api/UserManagement/GetActiveUsersList";
export const UserManagementGetTotalUsersList =
  "api/UserManagement/GetTotalUsersList";
export const UserManagementGetLockedUsersList =
  "api/UserManagement/GetLockedUsersList";
export const UserManagementGetUserList = "api/UserManagement/GetUserList";
export const UserManagementAddNewUser = "api/UserManagement/AddNewUser";
export const UserManagementUserFunctionality =
  "api/UserManagement/UserFunctionality";
export const UserManagementActionsOnUser = "api/UserManagement/ActionsOnUser";
export const UserManagementUpdateUserDetails =
  "api/UserManagement/UpdateUserDetails";
export const UserManagementMail = "api/UserManagement/TestMail";
export const UserManagementMail1 = "api/UserManagement/TestMail1";
export const UserManagementResetPassword = "api/UserManagement/ResetPassword";
export const VendorClaimsListOfVendorClaims =
  "api/VendorClaims/GetListOfVendorClaims";
export const VendorClaimsVendorClaimsInvoiceDetails =
  "api/VendorClaims/GetVendorClaimsInvoiceDetails";
export const VendorClaimsVendorClaimFilterFields =
  "api/VendorClaims/GetVendorClaimFilterFields";
export const VendorReportsReportList =
  "api/VendorReportsTestController/GetReportList";
export const VendorReportsDupReportNoActivity =
  "api/VendorReportsTestController/GetDupReportNoActivity";
export const VendorReportsPOStatusReport =
  "api/VendorReportsTestController/POStatusReport";
export const VendorReportsVendorParentChildRelationship =
  "api/VendorReportsTestController/VendorParentChildRelationship";
export const VendorReportsVendorAnalysisGetDupReport =
  "api/VendorReportsTestController/VendorAnalysisGetDupReport";
export const VendorReportsPOAlternatePayeeVendors =
  "api/VendorReportsTestController/GetPOAlternatePayeeVendors";
export const VendorReportsVendorAnalysisGetMissingDataReport =
  "api/VendorReportsTestController/GetVendorAnalysisGetMissingDataReport";
export const VendorReportsVendorByPaymentTerms =
  "api/VendorReportsTestController/GetVendorByPaymentTerms";
export const VendorReportsVendorSummary =
  "api/VendorReportsTestController/GetVendorSummary";
export const VendorReportsPotentialDup =
  "api/VendorReportsTestController/GetPotentialDup";
export const VendorReportsVendorsWithNoActivity =
  "api/VendorReportsTestController/GetVendorsWithNoActivity";
export const VendorReportsPOVendors =
  "api/VendorReportsTestController/GetPOVendors";
export const VendorReportsVendorsContracts =
  "api/VendorReportsTestController/GetVendorsContracts";
export const PreProcCriteriaEditFilterPageData =
  "api/AdditionalPreProcCriteria/GetEditFilterPageData";
export const IPQCFraudAndErrorFilterName =
  "api/IPQC/GetIPQCFraudAndErrorFilterName";
export const IPQCFraudAndErrorDetectionData =
  "api/IPQC/IPQCFraudAndErrorDetectionData";
export const IPQCFraudAndErrorCount = "api/IPQC/IPQCFraudAndErrorCount";
export const IPQCSaveRecordDetails = "api/IPQC/IPQCSaveRecordDetails";
export const IPQCFraudAndErrorFilterFields =
  "api/SavedFilter/GetIPQCFraudAndErrorFilterFields";
export const IPQCFraudAndErrorAddAdvanceFilter =
  "api/SavedFilter/AddIPQCFraudAndErrorAdvancedFilter";
export const IPQCFraudAndErrorExportAllToExcel =
  "api/IPQC/IPQCFraudAndErrorExportAllToExcel";
export const IPQCFraudAndErrorRunFilterWithSort =
  "api/SavedFilter/RunIPQCFraudAndErrorFilterWithSort";
export const GetCalculatedClaimValues = "api/Home/GetCalculatedClaimValues";
export const AddAdvancedFilter = "api/UserManagement/AddAdvancedFilter";
export const GetEditFilterPageData = "api/UserManagement/GetEditFilterPageData";
export const DeleteAdvancedFilter = "api/UserManagement/DeleteAdvancedFilter";
export const UserManagementGetFilterName = "api/UserManagement/GetFilterName";
export const UserManagementGetFilterFields = "api/UserManagement/GetFilterFields";
export const UserManagementExportToExcel = "api/UserManagement/ExportToExcel";
export const DownloadTemplates = "api/Download/GetTemplates";
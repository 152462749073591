import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { storage } from "../../../config/storage";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import { downloadFile } from '../../../helper';
import { Error_Msg } from "../../../config/index";
import {
    RevisitedClaimsClaimFilterFields,
    ReVisitedClaimsClaimList,
    RevisitedClaimsClaimCount,
    ReVisitedClaimsExportToExcel,
    ReVisitedClaimsExportAllToExcel,
    RevisitedClaimsPost
} from "../../../config/APINames";
import {
    INIT_CLAIM_FILTER_FIELDS,
    INIT_CLAIM_LIST,
    INIT_CLAIM_TOTAL_COUNT,
    INIT_CLAIM_RECORDS_EXPORT,
    INIT_CLAIM_RECORDS_EXPORT_ALL,
    INIT_GET_DUPS_CLAIM_DETAILS,
} from "./actionTypes";
import {
    fetchClaimList,
    fetchClaimTotalCount,
    fetchClaimRecordsExport,
    fetchClaimRecordsExportAll,
    fetchDupsClaimDetails,
} from "./actions";

export default function* watchLogin() {
    yield all([
        takeLatest(INIT_CLAIM_FILTER_FIELDS, initGetClaimFilterFieldsSaga),
        takeLatest(INIT_CLAIM_LIST, initGetClaimListSaga),
        takeLatest(INIT_CLAIM_TOTAL_COUNT, initClaimTotalCountSaga),
        takeLatest(INIT_CLAIM_RECORDS_EXPORT, initClaimRecordsExportSaga),
        takeLatest(INIT_CLAIM_RECORDS_EXPORT_ALL, initClaimRecordsExportAllSaga),
        takeLatest(INIT_GET_DUPS_CLAIM_DETAILS, initGetDupsClaimDetailsSaga),
    ]);
}

function* initGetClaimFilterFieldsSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { userName, clientName } = sessionDetails || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName,
            userName,
        });
        const response = yield call(axios, RevisitedClaimsClaimFilterFields, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        } else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetClaimListSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, ReVisitedClaimsClaimList, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        }
        else if (response !== Error_Msg) {
            yield put(fetchClaimList(response.data));
        }
    } catch (error) {
        generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
    }
}

function* initClaimTotalCountSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, RevisitedClaimsClaimCount, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        }
        else if (response !== Error_Msg) {
            yield put(fetchClaimTotalCount(response.data));
        }
    } catch (error) {
        generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
    }
}

function* initClaimRecordsExportSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        requestDetails.responseType = "blob";
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, ReVisitedClaimsExportToExcel, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        }
        else if (response !== Error_Msg) {
            yield put(fetchClaimRecordsExport(response.data));
            downloadFile(response.data, "DupsClaim.xlsx");
        }
    } catch (error) {
        generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
    }
}

function* initClaimRecordsExportAllSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        requestDetails.responseType = "blob";
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, ReVisitedClaimsExportAllToExcel, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        }
        else if (response !== Error_Msg) {
            yield put(fetchClaimRecordsExportAll(response.data));
            downloadFile(response.data, "DupsClaim.xlsx");
        }
    } catch (error) {
        generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
    }
}

function* initGetDupsClaimDetailsSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, RevisitedClaimsPost, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        }
        else if (response.data !== Error_Msg) {
            yield put(fetchDupsClaimDetails(response.data));
            if (action.callback) action.callback(response);
        }
    }
    catch (error) {
        if (action.callback) action.callback(error);
    }
}
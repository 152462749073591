import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { downloadFile } from '../../../helper';
import {
  INIT_FRAUD_AND_ERROR_DATA,
  INIT_FRAUD_AND_ERROR_EXPORT_FIELDS,
  INIT_FRAUD_AND_ERROR_FILTER_NAME,
  INIT_FRAUD_AND_ERROR_HEADER_FIELDS,
  INIT_FRAUD_AND_ERROR_PAGES_COUNT,
  INIT_FRAUD_AND_ERROR_ROLE_BASED_ACTIONS,
  INIT_FRAUD_AND_ERROR_RECORD_DETAILS,
  INIT_FRAUD_AND_ERROR_RUN_FILTER,
  INIT_FRAUD_AND_ERROR_FILTER_FIELDS,
  INIT_FRAUD_AND_ERROR_EDIT_FILTER_DETAILS,
  INIT_FRAUD_AND_ERROR_SAVE_FILTER_DETAILS,
  INIT_DELETE_FILTER_OPTION,
  INIT_FRAUD_AND_ERROR_EXPORT_RECORDS,
  INIT_FRAUD_AND_ERROR_DETAILS_DROPDOWN_FIELDS,
  INIT_FRAUD_AND_ERROR_SAVE_RECORD_DETAILS,
} from "./actionTypes";
import {
  UserActionAuthenticationGetRoleBasedActions,
  SavedFilterFraudAndErrorFilterName,
  ReVisitedDupsUnattendedExportFieldsList,
  ReVisitedDupsUnattendedDupsExceptionListHeaders,
  ReVisitedDupsUnattendedFraudAndErrorDetectionData,
  ReVisitedDupsUnattendedAiFraudAndErrorCount,
  ReVisitedDupsUnattendedPost,
  SavedFilterRunFraudAndErrorFilterWithSort,
  SavedFilterFraudAndErrorFilterFields,
  SavedFilterEditFilterPageData,
  SavedFilterAddFraudAndErrorAdvancedFilter,
  SavedFilterDeleteAdvancedFilter,
  ReVisitedDupsUnattendedFraudAndErrorExportAllToExcel,
  ReVisitedDupsUnattendedFraudAndErrorFieldValues,
  ReVisitedDupsUnattendedSaveRecordDetails,
} from "../../../config/APINames";
import {
  fetchExportFields,
  fetchFilterName,
  fetchHeaderFieldsList,
  fetchPageCount,
  fetchRoleBasedActions,
  fetchTableData,
  fetchFilterFields,
  fetchDropDownFields,
} from "./actions";
import { generateNotification } from "../../../helper/generateNotification";
export default function* watchFraudAndError() {
  yield all([
    takeLatest(INIT_FRAUD_AND_ERROR_DATA, initFraudAndErrorRowData),
    takeLatest(INIT_FRAUD_AND_ERROR_EXPORT_FIELDS, initExportedFields),
    takeLatest(INIT_FRAUD_AND_ERROR_FILTER_NAME, initFilterName),
    takeLatest(INIT_FRAUD_AND_ERROR_HEADER_FIELDS, initHeaderFields),
    takeLatest(INIT_FRAUD_AND_ERROR_PAGES_COUNT, initPageCount),
    takeLatest(INIT_FRAUD_AND_ERROR_ROLE_BASED_ACTIONS, initRoleBasedAction),
    takeEvery(INIT_FRAUD_AND_ERROR_RECORD_DETAILS, initRecordDetails),
    takeLatest(INIT_FRAUD_AND_ERROR_RUN_FILTER, initRunFilter),
    takeLatest(INIT_FRAUD_AND_ERROR_FILTER_FIELDS, initFilterFields),
    takeLatest(INIT_FRAUD_AND_ERROR_EDIT_FILTER_DETAILS, initEditFilterDetails),
    takeLatest(INIT_FRAUD_AND_ERROR_SAVE_FILTER_DETAILS, initSaveFilterDetails),
    takeLatest(INIT_DELETE_FILTER_OPTION, initDeleteFilter),
    takeLatest(INIT_FRAUD_AND_ERROR_EXPORT_RECORDS, initExportRecords),
    takeLatest(INIT_FRAUD_AND_ERROR_DETAILS_DROPDOWN_FIELDS, initDropDownList),
    takeLatest(INIT_FRAUD_AND_ERROR_SAVE_RECORD_DETAILS, initSaveRecordDetails),
  ]);
}
function* initFraudAndErrorRowData(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ReVisitedDupsUnattendedFraudAndErrorDetectionData,
      requestDetails
    );
    response = response.data;
    yield put(fetchTableData(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initExportedFields(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ReVisitedDupsUnattendedExportFieldsList,
      requestDetails
    );
    response = response.data;
    yield put(fetchExportFields(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initFilterName(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      SavedFilterFraudAndErrorFilterName,
      requestDetails
    );
    response = response.data.length > 0 ? response.data : [];
    yield put(fetchFilterName(response));
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initHeaderFields(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ReVisitedDupsUnattendedDupsExceptionListHeaders,
      requestDetails
    );
    response = response.data;
    yield put(fetchHeaderFieldsList(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initPageCount(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ReVisitedDupsUnattendedAiFraudAndErrorCount,
      requestDetails
    );
    response = response.data;
    yield put(fetchPageCount(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initRoleBasedAction(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      UserActionAuthenticationGetRoleBasedActions,
      requestDetails
    );
    response =
      response.data.resultSet.actionNames !== undefined
        ? response.data.resultSet.actionNames
        : [];
    yield put(fetchRoleBasedActions(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initRecordDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ReVisitedDupsUnattendedPost,
      requestDetails
    );
    response =
      payload.target === "search"
        ? response.data.details.RecordID !== 0
          ? [response.data.details]
          : []
        : payload.target === "recordSelect"
          ? payload.Command === "FraudAndErrorDetails"
            ? response.data.details
            : response.data.Outputdetails
          : "";
    payload.target === "search" &&
      response.length === 0 &&
      generateNotification("atcat.pleaseProvideValidRecordId", "warning");
    payload.target === "search" && response.length > 0 && callback(response);
    payload.target === "recordSelect" && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initRunFilter(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      SavedFilterRunFraudAndErrorFilterWithSort,
      requestDetails
    );
    response = response.data;
    yield put(fetchTableData(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initFilterFields(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      SavedFilterFraudAndErrorFilterFields,
      requestDetails
    );
    response.data ===
      "Request could not be processed at this time. Please contact support team." &&
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    response =
      response.data !==
        "Request could not be processed at this time. Please contact support team." &&
        response.data.length > 0
        ? response.data
        : [];
    yield put(fetchFilterFields(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initEditFilterDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      SavedFilterEditFilterPageData,
      requestDetails
    );
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initSaveFilterDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      SavedFilterAddFraudAndErrorAdvancedFilter,
      requestDetails
    );
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initDeleteFilter(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      SavedFilterDeleteAdvancedFilter,
      requestDetails
    );
    response = response.data;
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initExportRecords(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    requestDetails.responseType = "blob";
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ReVisitedDupsUnattendedFraudAndErrorExportAllToExcel,
      requestDetails
    );
    response = response.data;
      downloadFile(response, "FraudAndError.xlsx");
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initDropDownList(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ReVisitedDupsUnattendedFraudAndErrorFieldValues,
      requestDetails
    );
    response =
      response.data.FraudAndErrorField.length > 0
        ? response.data.FraudAndErrorField
        : [];
    yield put(fetchDropDownFields(response));
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initSaveRecordDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ReVisitedDupsUnattendedSaveRecordDetails,
      requestDetails
    );
    response = response.data;
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import generateKey from "../../../config/generateKey";
import { downloadFile } from '../../../helper';
import {
  GetAIAdhocExportFileRecords,
  GetAIAdhocSelectedExportRecord,
  AIExportToExcel,
  AISubmitAdhoc,
  AdhocExcelValidation,
} from "../../../config/APINames";
import {
  INIT_AI_ADHOC_EXPORT_EXCEPTIONS,
  INIT_AI_ADHOC_JOB_DETAILS,
  INIT_AI_ADHOC_JOB_LIST,
  INIT_AI_ADHOC_EXCEL_VALIDATION,
  INIT_AI_ADHOC_RUN_DUPS,
} from "./actionTypes";
import { fetchJobDetails, fetchJobList } from "./actions";

export default function* watchAIAdhoc() {
  yield all([
    takeLatest(INIT_AI_ADHOC_JOB_LIST, initJobList),
    takeLatest(INIT_AI_ADHOC_JOB_DETAILS, initJobDetails),
    takeLatest(INIT_AI_ADHOC_EXPORT_EXCEPTIONS, initExportExceptions),
    takeLatest(INIT_AI_ADHOC_EXCEL_VALIDATION, initExcelValidation),
    takeLatest(INIT_AI_ADHOC_RUN_DUPS, initRunAdhocDups),
  ]);
}
function* initJobList(action) {
  const { payload, callback } = action;
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      GetAIAdhocExportFileRecords,
      requestDetails
    );
    response = response.data.exceptionList !== undefined ? response.data : {};
    yield put(fetchJobList(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initJobDetails(action) {
  const { payload, callback } = action;
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      GetAIAdhocSelectedExportRecord,
      requestDetails
    );
    response = response.data.exceptionList !== undefined ? response.data : {};
    if (response.exceptionList !== undefined) {
      response.count[0] === 0 &&
        generateNotification("atcat.noExceptionCaught", "success");
    }
    yield put(fetchJobDetails(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initExportExceptions(action) {
  const { payload } = action;
  try {
    const requestDetails = postRequestDetails();
    requestDetails.responseType = "blob";
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, AIExportToExcel, requestDetails);
    if (response.data !== undefined) {
      downloadFile(response.data, "AdhocDups.xlsx");
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initExcelValidation(action) {
  const { payload, callback } = action;
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, AdhocExcelValidation, requestDetails);
    response = response.data.resultStatus;
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initRunAdhocDups(action) {
  const { payload, callback } = action;
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, AISubmitAdhoc, requestDetails);
    if (response && response.data.resultStatus) {
      generateNotification(generateKey(response.data.message), "success");
      callback !== undefined && callback();
    } else {
      if (response.data.resultSet !== null) {
        callback !== undefined && callback(response.data.resultSet);
      } else {
        callback !== undefined && callback();
        generateNotification(generateKey(response.data.message), "danger");
      }
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

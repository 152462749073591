import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { storage } from "../../../config/storage";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import generateKey from "../../../config/generateKey";
import { downloadFile } from '../../../helper';
import { Error_Msg } from "../../../config/index";
import {
  ReVisitedDupsUnattendedFilterName,
  ReVisitedDupsUnattendedDupsExceptionListHeaders,
  ReVisitedDupsUnattendedExceptionList,
  ReVisitedDupsUnattendedFilterFields,
  ReVisitedDupsUnattendedAddAdvancedFilter,
  ReVisitedDupsUnattendedEditFilterPageData,
  ReVisitedDupsUnattendedDupsCount,
  ReVisitedDupsUnattendedDeleteAdvancedFilter,
  ReVisitedDupsUnattendedExportToExcel,
  ReVisitedDupsUnattendedDupsExportAllToExcel,
  ReVisitedDupsUnattendedPost,
  ClientUDLGetUDLDetails,
  ReVisitedDupsUnattendedMassReleaseWithComments,
} from "../../../config/APINames";
import {
  INIT_EXCEPTION_FILTER_NAMES,
  INIT_EXCEPTION_LIST_HEADER_NAMES,
  INIT_EXCEPTION_LIST,
  INIT_EXCEPTION_FILTER_COLUMNS,
  INIT_EXCEPTION_FILTER_DETAILS,
  INIT_SAVE_FILTER_EXCEPTION,
  INIT_EXCEPTION_TOTAL_COUNT,
  INIT_DELETE_FILTER_EXCEPTION,
  INIT_EXCEPTION_RECORDS_EXPORT,
  INIT_EXCEPTION_RECORDS_EXPORT_ALL,
  INIT_RELEASE_UNRELEASE_EXCEPTIONS,
  INIT_BULK_RELEASE_UDL_LIST,
  INIT_MASS_RELEASE_WITH_COMMENTS,
} from "./actionTypes";
import {
  fetchExceptionListHeaderNames,
  fetchExceptionList,
  fetchExceptionFilterColumns,
  fetchExceptionTotalCount,
  fetchExceptionRecordsExport,
  fetchExceptionRecordsExportAll,
  fetchBulkReleaseUdlList,
} from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(INIT_EXCEPTION_FILTER_NAMES, initGetExceptionFilterNamesSaga),
    takeLatest(
      INIT_EXCEPTION_LIST_HEADER_NAMES,
      initGetExceptionListHeaderNamesSaga
    ),
    takeLatest(INIT_EXCEPTION_LIST, initGetExceptionListSaga),
    takeLatest(INIT_EXCEPTION_FILTER_COLUMNS, initExceptionFilterColumsSaga),
    takeLatest(INIT_EXCEPTION_FILTER_DETAILS, initExceptionFilterDetailsSaga),
    takeLatest(INIT_SAVE_FILTER_EXCEPTION, initSaveExceptionFilterSaga),
    takeLatest(INIT_EXCEPTION_TOTAL_COUNT, initExceptionTotalCountSaga),
    takeLatest(INIT_DELETE_FILTER_EXCEPTION, initDeleteExceptionFilterSaga),
    takeLatest(INIT_EXCEPTION_RECORDS_EXPORT, initExceptionRecordsExportSaga),
    takeLatest(
      INIT_EXCEPTION_RECORDS_EXPORT_ALL,
      initExceptionRecordsExportAllSaga
    ),
    takeEvery(
      INIT_RELEASE_UNRELEASE_EXCEPTIONS,
      initReleaseUnReleaseExceptionsSaga
    ),
    takeLatest(INIT_BULK_RELEASE_UDL_LIST, initGetBulkReleaseUdlListSaga),
    takeLatest(
      INIT_MASS_RELEASE_WITH_COMMENTS,
      initMassReleaseWithCommentsSaga
    ),
  ]);
}

function* initGetExceptionFilterNamesSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName, sourceDomain, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      userName,
      userId,
      sourceDomain,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedFilterName,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetExceptionListHeaderNamesSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedDupsExceptionListHeaders,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchExceptionListHeaderNames(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initGetExceptionListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedExceptionList,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
      yield put(
        fetchExceptionList({ exceptionList: [], SortOrder: "", gridList: {} })
      );
    } else if (response !== Error_Msg) {
      yield put(fetchExceptionList(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initExceptionFilterColumsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedFilterFields,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchExceptionFilterColumns(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initExceptionFilterDetailsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName, sourceDomain, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      sourceDomain,
      userId,
      userName,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedEditFilterPageData,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initSaveExceptionFilterSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName, sourceDomain, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      sourceDomain,
      userId,
      userName,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedAddAdvancedFilter,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initExceptionTotalCountSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedDupsCount,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
      yield put(fetchExceptionTotalCount(0));
    } else if (response !== Error_Msg) {
      yield put(fetchExceptionTotalCount(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initDeleteExceptionFilterSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      userName,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedDeleteAdvancedFilter,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initExceptionRecordsExportSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    requestDetails.responseType = "blob";
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedExportToExcel,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchExceptionRecordsExport(response.data));
      downloadFile(response.data, "ExceptionList.xlsx");
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initExceptionRecordsExportAllSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    requestDetails.responseType = "blob";
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedDupsExportAllToExcel,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchExceptionRecordsExportAll(response.data));
      downloadFile(response.data, "ExceptionList.xlsx");
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initReleaseUnReleaseExceptionsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      ClientName: clientName,
      UserName: userName,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedPost,
      requestDetails
    );
    if (response.data === Error_Msg) {
      const regMatch = /[a-z]/i.test(payload.CommandArgs);
      if (regMatch || payload.CommandArgs.length > 10) {
        if (action.callback) action.callback(response);
      } else {
        generateNotification(
          "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
          "danger"
        );
      }
    } else if (
      response.data === "Exception Sets Released Successfully" ||
      response.data === "Exception Set Unreleased Successfully" ||
      response.data.msg === "All records released successfully"
    ) {
      if (action.callback) action.callback(response);
    } else if (
      response.data !==
      "Request could not be processed at this time. Please contact support team."
    )
      if (action.callback) action.callback(response.data);
      else if (response.data.msg === "No valid exceptions exist")
        generateNotification("atcat.noValidExceptionsExist", "danger");
      else {
        generateNotification(generateKey(response.data), "danger");
      }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initGetBulkReleaseUdlListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, ClientUDLGetUDLDetails, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseUdlList(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initMassReleaseWithCommentsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      ClientName: clientName,
      UserName: userName,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedMassReleaseWithComments,
      requestDetails
    );
    if (response.data === Error_Msg)
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    else if (
      response.data.msg === "Selected exceptions released successfully" ||
      response.data === "Exception Sets Released Successfully" ||
      response.data.msg === "All records released successfully"
    ) {
      if (action.callback) action.callback(response);
    } else {
      generateNotification(generateKey(response.data.msg), "danger");
      if (action.callback) action.callback(false);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
